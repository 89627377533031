// eslint-disable-next-line import/no-anonymous-default-export
export default {
  screens: [
    {
      observation: 'intro_sm',
      module: 'flash_card',
      type: 'intro',
      meta: {
        hiddenHeader: true,
        image: 'intro_sm',
        v2: true,
        agentImg: true,
        hideBottumNavigation: true
      },
      auto_advance: true,
      timer: {
        time: 18000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm_instruc',
      module: 'flash_card',
      type: 'game_instructions',
      meta: {
        image: 'gameI-intro1',
        imageMobile: 'gameI-mobile-intro1'
      },
      auto_advance: true,
      timer: {
        time: 1800000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm_instruc2',
      module: 'flash_card',
      type: 'game_instructions',
      meta: {
        image: 'gameI-intro2',
        imageMobile: 'gameI-mobile-intro2'
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm1_preGuess',
      module: 'flash_card',
      type: 'simple_slider',
      help: true,
      meta: {
        min: 0,
        max: 20,
        marks: {},
        defaultValue: 10,
        options: [
          {},
          {},
          {}
        ],
        center: true
      },
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      back: false,
    },
    {
      observation: 'sm1start',
      module: 'flash_card',
      type: 'game_instructions',
      meta: {
        image: 'game-welcome-level1',
        imageMobile: 'game-welcome-level1'
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm1_1_0',
      module: 'flash_card',
      type: 'game_start',
      meta: {
        image: "game-mobile",
        hideBottumNavigation: true
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: false,
        enabled: false
      },
    },
    {
      observation: 'level1',
      module: 'flash_card',
      type: 'game_page',
      meta: {
        crossIcon: "cross",
        practiceCount: 8,
        keybord: "keybord",
        blankImage: "blank-image",
        image: "game-mobile",
        images: ["game-mobile", "game_petrol", "game_bulb"],
        levelCompleteImage: "level-complete-image1",
        randomNumbers: Array.from({ length: 20 }, () =>
          Math.floor(Math.random() * 3)
        ),
        time: 45
      },
      auto_advance: true,
      timer: {
        time: 45000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: false,
        enabled: false
      },
    },
    {
      observation: 'sm1completed',
      module: 'flash_card',
      type: 'game_result',
      meta: {
        levelCompleteImage: "level-complete-image1",
        result_from: "level1",
        score: 10
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm2_preGuess',
      module: 'flash_card',
      type: 'game_simple_slider',
      help: true,
      meta: {
        min: 0,
        max: 20,
        defaultValue: 10,
        title: {
          alignment: 'center'
        },
        options: [
          {},
          {}
        ],
        center: true,
        image: "image30Sec"
      },
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      back: false,
    },
    {
      observation: 'sm2start',
      module: 'flash_card',
      type: 'game_instructions',
      meta: {
        image: 'game-welcome-level2',
        imageMobile: 'game-welcome-level2'
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },

    {
      observation: 'sm2_1_0',
      module: 'flash_card',
      type: 'game_start',
      meta: {
        image: "game_2_1"
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: false,
        enabled: false
      },
    },
    {
      observation: 'level2',
      module: 'flash_card',
      type: 'game_page',
      meta: {
        crossIcon: "cross",
        practiceCount: 0,
        keybord: "keybord",
        blankImage: "blank-image",
        image: "game-mobile",
        images: ["game_2_1", "game_2_2", "game_2_3"],
        levelCompleteImage: "level-complete-image1",
        randomNumbers: Array.from({ length: 20 }, () =>
          Math.floor(Math.random() * 3)
        ),
        time: 30
      },
      auto_advance: true,
      timer: {
        time: 30000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: false,
        enabled: false
      },
    },
    {
      observation: 'sm2completed',
      module: 'flash_card',
      type: 'game_result',
      meta: {
        levelCompleteImage: "level-complete-image2",
        score: 10,
        result_from: "level2",
      },
      auto_advance: true,

      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm3_preGuess',
      module: 'flash_card',
      type: 'game_simple_slider',
      help: true,
      meta: {
        min: 0,
        max: 20,
        defaultValue: 10,
        title: {
          alignment: 'center'
        },
        options: [
          {},
          {}
        ],
        center: true,
        image: "image30Sec"
      },
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
      back: false,
    },
    {
      observation: 'sm3_1_0',
      module: 'flash_card',
      type: 'game_start',
      meta: {
        image: "game_3_1"
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: false,
        enabled: false
      },
    },
    {
      observation: 'level3',
      module: 'flash_card',
      type: 'game_page',
      meta: {
        crossIcon: "cross",
        practiceCount: 0,
        keybord: "keybord",
        blankImage: "blank-image",
        image: "game-mobile",
        images: ["game_3_1", "game_3_2", "game_3_3"],
        levelCompleteImage: "level-complete-image1",
        randomNumbers: Array.from({ length: 20 }, () =>
          Math.floor(Math.random() * 3)
        ),
        time: 30
      },
      auto_advance: true,
      timer: {
        time: 30000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: false,
        enabled: false
      },
    },
    {
      observation: 'sm3completed',
      module: 'flash_card',
      type: 'game_result',
      meta: {
        levelCompleteImage: "level-complete-image3",
        score: 10,
        result_from: "level3",
      },
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: false
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm_guessCorrect',
      module: 'flash_card',
      type: 'numerical_slider',
      help: true,
      meta: {
        center: true,
        min: 0,
        max: 20,
      },
      back: true,
      auto_advance: true,
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: true,
        enabled: true
      },
    },
    {
      observation: 'sm_guessCorrect_rel',
      module: 'flash_card',
      type: 'simple_slider',
      help: true,
      auto_advance: true,
      meta: {
        min: 1,
        max: 100,
        defaultValue: 50,
        title: {
          alignment: 'left'
        },
        options: [
          { label: "Worse" },
          { label: "Average" },
          { label: "Better" }
        ],
        center: true,
      },
      timer: {
        time: 5000,
        extend: false,
        pause: false,
        visible: true
      },
      next: {
        visible: true,
        enabled: true
      },
    },
  ],
  screen_timers: [

  ],
  special_timers: [

  ]
};
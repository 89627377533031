import React, { useState,useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import entrepreneurs from '../schema/entrepreneurs.json';
import uientrepreneurs from '../uischema/uientrepreneurs.json';
import {
    materialCells,
    materialRenderers,
} from '@jsonforms/material-renderers';
import { Grid, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Financial } from './index';
const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: 60,
            paddingLeft: '20px',
            paddingRight: '20px',
            maxWidth:'80%',
            margin: '0 auto'
        },
        heading:{
            fontSize:'3rem'
        }
    })
);
// dummy data
const value = {
};
//ui renders
const renderers = [...materialRenderers];
const InvestmentOfficers = () => {
    const classes = useStyles();
    const [jsonformsInputData, setJsonformsInputData] = useState(value);
    const [jsonformsOutputData, setJsonformsOutputData] = useState(value);
    const [haveError,setHaveError] = useState(true);
    const [showLand,setShowLand] = useState(true);
    useEffect(()=>{
        if(showLand){
            if(window.part){
               setJsonformsInputData(window.part)
            }
        }
       },[showLand]);
    
    const formAction = (data, error) => {
        setJsonformsOutputData(data);
       
        if(error.length<1){
        if(data && data.FIs && data.clientId){
            setHaveError(false);
            window.part = data;
            window.LenddoVerifyConfig.application_id =`${((Math.floor(new Date().getTime() / 1000)).toString(36) + Math.floor(Math.random() * 10))}_${data.FIs.toLocaleLowerCase().replace(" ","_")}`;
         }
        }
    }
    const handleObservationChange = (a) => {

        window.part = jsonformsOutputData;
        setShowLand(a);
      };
    return (<>
       {showLand ? <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <JsonForms
                        schema={entrepreneurs}
                        uischema={uientrepreneurs}
                        data={jsonformsInputData}
                        renderers={renderers}
                        cells={materialCells}
                        validationMode={'ValidateAndHide'}
                        onChange={({ errors, data }) => formAction(data, errors)}
                    />
                </Grid>
                <Grid  item  xs={12}>
                 <Button variant="contained" className="next_btn" disabled={haveError} onClick={() => setShowLand(false)}>Next</Button>
                </Grid>
            </Grid>
        </div> : <Financial goback={handleObservationChange}/>}
    </>);
}

export default InvestmentOfficers;
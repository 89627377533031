import './App.css';
/* Uncdf Route */
import Routing from "./routing_uncdf";

import { loadImage } from "./helpers/image_loader";
const { images } = require ('./helpers/assets.json');

function App() {
  return (
    <div className="App">
      <Routing />
      <div className="d-none">
      {
        images.map((im,i) =><img key={i} src={loadImage(im.split('.')[0])} alt="lenddo" />)

      }
      </div>
    </div>
  );
}

export default App;

import _get from 'lodash.get';
let count = 0;
const mapObservation = (
  { type, ...screen },
  {
    parsed_observations, observation_key, observations,
    view_counts, screen_timers, help_timers, display_order, locales
  }, i
) => {

  switch (type) {
    case 'intro':
    case 'multiple_instructions':
    case 'game_instructions':
    case 'instructions':
      parsed_observations[observation_key] = {
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: null,
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };

      break;
    case 'text_input':
      const observation_keys = Object.keys(observations);

      observation_keys.forEach(observation => {
        const parsed = {
          [observation]: { responseValue: _get(observations, `${observation}.value`, '') }
        };

        if (observation === observation_key) {
          parsed[observation] = {
            ...parsed[observation],
            viewCount: _get(view_counts, 'views', 0),
            timeElapsed: _get(screen_timers, 'total', 0),
            helpTimeElapsed: _get(help_timers, 'total', 0),
            displayOrder: display_order,
            languageSequence: _get(locales, `${observation_key}`, [])
          }
        }

        parsed_observations = {
          ...parsed_observations,
          ...parsed
        }
      });
      break;
    case 'radio_group':
    case 'radio_group_image':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value', null),
        responseLabel: _get(observations, 'label', null),
        responseSequence: observations.sequence,
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };

      const other_option = observations['other_option_value'];

      if (other_option) {
        const other_option_keys = Object.keys(other_option);

        other_option_keys.forEach(key => {
          parsed_observations = {
            ...parsed_observations,
            [key]: {
              responseValue: _get(other_option, `${key}`, '')
            }
          }
        });
      }

      break;
    case 'checkbox_group':
    case 'game_page':
      let firstHalf = 0;
      let secondHalf = 0;
      if (screen.module.match('v2')) {
        const gameScreen = Object.keys(observations).length / 2;
        Object.keys(observations).map((key) => {
          if(observations[key].value){
          let { correctAnswer = false } = observations[key].value;
          if (gameScreen > key) {
            if (correctAnswer) ++firstHalf;
          } else {
            if (correctAnswer) ++secondHalf;
          }
        }
        })
        observations = Object.keys(observations).map((key) => {
          if(observations[key].value){
          let { userAnswer, correctAnswer = false, objID } = observations[key].value;
          if (userAnswer !== null) ++count
          return {
            objID,
            fc_noaction: userAnswer === null ? true : false,
            fc_help: help_timers.total ? true : false,
            fc_correct: (correctAnswer ? 'correct' : false),
            fc_incorrect: (!correctAnswer ? 'incorrect' : false),
          };
        }
        })
        parsed_observations[observation_key] = {
          gameScreen: observations,
          fc_improvement: firstHalf < secondHalf,
          fc_level: count,
        }
      } else {
        parsed_observations[observation_key] = {
          responseValue: _get(observations, 'value', null),
          responseLabel: _get(observations, 'labels', null),
          responseSequence: observations.sequence,
          viewCount: _get(view_counts, 'views', 0),
          timeElapsed: _get(screen_timers, 'total', 0),
          helpTimeElapsed: _get(help_timers, 'total', 0),
          displayOrder: display_order,
          languageSequence: _get(locales, `${observation_key}`, [])
        };
      }

      break;
    case 'fin_choice':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value', null),
        responseLabel: _get(observations, 'label', null),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };
      break;
    case 'pick_img':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value',null),
        responseLabel: _get(observations, 'label', ""),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };
      break;
    case 'fire_building':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value', null),
        responseLabel: _get(observations, 'label', null),
        viewCount: _get(view_counts, 'views', 0),
        fb_elapsedtime: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, []),
        fb_noaction: _get(observations, 'noaction', null),
        fb_timesfilled: _get(observations, 'timesfilled', null),
        fb_final: _get(observations, 'final', null),
        fb_selection: _get(observations, 'selection', null),
      };
      break;
    case 'image_slider':
    case 'numerical_slider':
    case 'tooltip_slider':
    case 'simple_slider':
    case 'game_simple_slider':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value', null),
        responseLabel: _get(observations, 'label', null),
        sliderDistance: _get(observations, 'distance', 0),
        sliderTime: _get(observations, 'time', 0),
        sliderDirection: _get(observations, 'direction', []),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };
      const other_options = observations['other_option'];

      if (other_options) {
        Object.keys(other_options).forEach(() => {
          parsed_observations[observation_key] = {
            ...parsed_observations[observation_key],
            responseValue: _get(other_options, `value`, ''),
            responseLabel: _get(other_options, `labels`, '')
          }
        });
      }
      break;
    case 'digit_span':
      const existing_keys = Object.keys(parsed_observations);

      const generate_observation = _get(screen, 'meta.generate.observation', observation_key + '_generate');
      const remind_observation = _get(screen, 'meta.remind.observation', observation_key + '_remind');
      const isCorrect = `${observations.generated}` === `${_get(observations, 'answer', '')}`;

      parsed_observations[generate_observation] = {
        responseValue: `${observations.generated}`,
        helpTimeElapsed: _get(help_timers, 'total', null),
        viewCount: _get(view_counts, 'views', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };

      let answer_mock_display_order = display_order + 2;

      if (!existing_keys.includes(remind_observation)) {
        parsed_observations[remind_observation] = {
          helpTimeElapsed: _get(help_timers, 'total', null),
          viewCount: _get(view_counts, 'views', 0),
          displayOrder: display_order + 1,
          languageSequence: _get(locales, `${observation_key}`, [])
        };
      } else {
        answer_mock_display_order = display_order + 1;

        parsed_observations[remind_observation] = {
          ...parsed_observations[remind_observation],
          viewCount: _get(view_counts, 'views', 0) + 1,
          languageSequence: parsed_observations[remind_observation].languageSequence.concat(_get(locales, `${observation_key}`, []))
        };
      }

      parsed_observations[observation_key] = {
        responseValue: `${_get(observations, 'answer', '')}`,
        responseLabel: `${_get(observations, 'answer', '')}`,
        responseSequence: [`${_get(observations, 'answer', '')}`],
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: answer_mock_display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };
      const key = observation_key.includes('redo') ? 'dsfredo_score' : 'dsf_score';
      parsed_observations[key] = {
        responseValue: `${`${observations.generated}`.length + (isCorrect ? 1 : -1)}`
      }
      break;
    case 'find_pattern':
      if (
        [
          "conscientiousness_highachieving",
          "lifesatisfaction1",
          "conscientiousness_plan5",
        ].includes(observations.label)
      ) {
        parsed_observations[observation_key] = {
          responseValue: observations.value.value||null,
          responseLabel: observations.value.label||null,
          viewCount: _get(view_counts, "views", 0),
          timeElapsed: _get(screen_timers, "total", 0),
          helpTimeElapsed: _get(help_timers, "total", 0),
          displayOrder: display_order,
          languageSequence: _get(locales, `${observation_key}`, []),
        };
      }else{

      parsed_observations[observation_key] = {
        rt_noaction: observations.value !== undefined ? false : true,
        rt_label: _get(observations, 'value.label', null),
        rt_value: _get(observations, 'value.value', null),
        rt_elapsedtime: _get(screen_timers, 'tota l', 0),
        rt_elapsedtime_i: _get(screen_timers, 'total', 0),
        rt_help: Object.keys(help_timers).length !== 0 ? true : false,
        rt_correct: _get(observations, 'value', null),
        rt_level: +observation_key.split("rt_level")[1],
      };
    }
      break;

    case 'truk_module':
      const { totalScore } = observations;
      const { meta } = screen;
      parsed_observations[observation_key] = {
        st_noaction: totalScore && (totalScore !== meta?.InitScore) ? false : true,
        st_elapsedtime: _get(screen_timers, 'total', 0),
        st_elapsedtime_i: _get(screen_timers, 'total', 0),
        st_timesfilled: _get(observations, 'count', 0),
        st_score: _get(observations, 'totalScore', 0),
        st_sanddisch: (_get(observations, 'totalScore', 0) > 60 )? true : false
      };
      break;
    case 'coin_game':
      parsed_observations[observation_key] = {
        cgp_elapsedtime: _get(observations, 'totalTime', 0),
        cgp_elapsedtime1: _get(observations, 'timeSequence', [0])[0],
        cgp_noaction: Object.keys(observations).length === 0 ? true : false,
        cgp_help: Object.keys(help_timers).length !== 0 ? true : false,
        cgp_change: _get(observations, 'totalScore', 0),
        cgp_exercisedist1: { responseSequence: observations?.clickSequence || [], timeSequence: observations?.timeSequence || [], },
      };
      break;

    case 'hover_slider':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'activeIndex', 0),
        responseLabel: _get(observations, 'activeIndex', 0),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order + 1,
        languageSequence: _get(locales, `${observation_key}`, [])
      };
      break;

    case 'group_image_button':

      parsed_observations[observation_key] = {
        selectedValues: _get(observations, 'value', []),
        selectedLabels: _get(observations, 'label', []),
        responseSequence: _get(observations, 'sequence', []),
      };
      break;

    case 'combo_box':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'date', null),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      }
      break;

    case 'fill_amount':
      parsed_observations[observation_key] = {
        responseValue: +_get(observations, 'value', null).replace(/[,$]/g, ''),
        responseLabel: _get(observations, 'value', null),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      }
      break;
    case 'start_trans':
      parsed_observations[observation_key] = {
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: null,
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, []),
        startTime: new Date()
      }
      break;
    case 'servey_rating':
      parsed_observations[observation_key] = {
        survey_satisfaction1: {
          'responseValue': _get(observations, 'value') && _get(observations, 'value') != null ? _get(observations, 'value') : 0,
          'responseLabel': _get(observations, 'label') && _get(observations, 'label') != null ? _get(observations, 'label') : 0,
          'responseSequence': observations.sequence,
          viewCount: _get(view_counts, 'views', 0),
          timeElapsed: _get(screen_timers, 'total', 0),
          helpTimeElapsed: _get(help_timers, 'total', 0),
          displayOrder: display_order,
          languageSequence: _get(locales, `${observation_key}`, [])
        },
        survey_satisfaction2: {
          'responseValue': _get(observations, 'value1') && _get(observations, 'value1') != null ? _get(observations, 'value1') : 0,
          'responseLabel': _get(observations, 'label1') && _get(observations, 'label1') != null ? _get(observations, 'label1') : 0,
          'responseSequence': observations.sequence1,
          viewCount: _get(view_counts, 'views', 0),
          timeElapsed: _get(screen_timers, 'total', 0),
          helpTimeElapsed: _get(help_timers, 'total', 0),
          displayOrder: display_order,
          languageSequence: _get(locales, `${observation_key}`, [])
        },
        survey_satisfaction3: {
          'responseValue': _get(observations, 'value2') && _get(observations, 'value2') != null ? _get(observations, 'value2') : 0,
          'responseLabel': _get(observations, 'label2') && _get(observations, 'label2') != null ? _get(observations, 'label2') : 0,
          'responseSequence': observations.sequence2,
          viewCount: _get(view_counts, 'views', 0),
          timeElapsed: _get(screen_timers, 'total', 0),
          helpTimeElapsed: _get(help_timers, 'total', 0),
          displayOrder: display_order,
          languageSequence: _get(locales, `${observation_key}`, [])
        },
      }
      break;
    case 'end_intro':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value', 0),
        responseLabel: _get(observations, 'value', 0),
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      }
      break;
    case 'confidentiality_check':
      parsed_observations[observation_key] = {
        responseValue: _get(observations, 'value', null),
        responseLabel: _get(observations, 'labels', null),
        responseSequence: observations.sequence,
        viewCount: _get(view_counts, 'views', 0),
        timeElapsed: _get(screen_timers, 'total', 0),
        helpTimeElapsed: _get(help_timers, 'total', 0),
        displayOrder: display_order,
        languageSequence: _get(locales, `${observation_key}`, [])
      };
      break;
    default:
  }
  return parsed_observations
};

const mapSpecialTimer = ({ screen_timers, special_timers, module }) => {
  let parsed = {};
  const TS = {};

  special_timers.forEach((timer) => {
    if (timer.module === module) {
      timer.observations.forEach(screen => {
        const target_screen_timer = screen_timers.filter(timer => timer.screen === _get(screen, `${screen}`, screen))[0] || {};

        if (TS[timer.name]) {
          TS[timer.name] = TS[timer.name] + _get(target_screen_timer, `total`, _get(screen, `${screen}`, 0))
        } else {
          TS[timer.name] = _get(target_screen_timer, `total`, _get(screen, `${screen}`, 0))
        }
      });
    }
  });

  Object.keys(TS).forEach(ts => {
    parsed = {
      ...parsed,
      [ts]: { timeElapsed: TS[ts] }
    }
  });

  return parsed
};
export const combineObservations = (module, { common, special_timers }, screens = [], observations = {}, view_counts = [], screen_timers = [], help_timers = [], locales = {}, version = 'V1') => {
  let parsed_observations = {};
  const module_observations = observations[module] || {};

  const observation_keys = Object.keys(module_observations);

  const non_order_modules = ['digit_span'];

  observation_keys.map((key, i) => {
    let target_screen = screens.filter(screen => screen.observation === key)[0];

    if (target_screen.key && common[target_screen.key]) {
      target_screen = {
        ...common[target_screen.key],
        ...target_screen
      }
    }

    let display_order = Object.keys(parsed_observations).length;

    const target_view_counts = view_counts.filter((view, index) => {
      if (view.screen === key && !non_order_modules.includes(target_screen.module)) display_order = index;
      return view.screen === key
    })[0] || {};
    const target_screen_timers = screen_timers.filter(timer => timer.screen === key)[0] || {};
    const target_help_timers = help_timers.filter(timer => timer.screen === key)[0] || {};
    if (target_screen) {
      parsed_observations = mapObservation(target_screen, {
        parsed_observations,
        observation_key: key,
        observations: module_observations[key] || {},
        view_counts: target_view_counts,
        screen_timers: target_screen_timers,
        help_timers: target_help_timers,
        display_order,
        locales,
      }, i);
    }
  });

  const multi_screen_timers = mapSpecialTimer({ special_timers: special_timers || [], screen_timers, parsed_observations, module });

  parsed_observations = {
    ...multi_screen_timers,
    ...parsed_observations
  };

  return parsed_observations
};

import React  from 'react';
import { Grid, Typography } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: 60,
            paddingLeft: '20px',
            paddingRight: '20px'
        },
        heading:{
            fontSize:'2rem'
        }
    })
);
const NotFound = () => {
    const classes = useStyles();
    
    return (<>
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1" align="center" gutterBottom className={classes.heading}>
                      404 Not found
                    </Typography>
                </Grid>
            
            </Grid>
        </div>
    </>);
}

export default NotFound;
import React, { useState, useEffect, useRef } from 'react';
import { Nav, NavButtons, Header, Overlay, ArrowLeft, ArrowRight } from './PlayerStyle';
import { ThemeProvider } from 'styled-components';

import {
  useScreenTimer,
  useNavigationState,
  useTimer,
  RuleManager,
  useScreenViewCounter,
  useTranslationManager,
  useLanguageSequence
} from '../../hooks';

import Timer from '../Timer/Timer';
import Template from '../../templates/Template';
import HelperModal from '../HelperModal/HelperModal';
import { CenterContent } from '../../assets/styles/BaseStyle';
import ProgressBar from '../ProgressBar/ProgressBar';

import { LocaleContext, translations } from '../../context/LocalContext';
import { combineObservations } from '../../helpers/observations';
import { useRandomScreen, screenManager, nextScreenChecker, observationState } from '../../helpers/player';
import setTheme from '../../context/ThemeProvider';

import _get from 'lodash.get';

const Player = ({ module, theme, navigate, navigation, header, onNavigate, onObservation, onModuleFinish }) => {
  const screens = useRandomScreen(module) || [];

  const screenRef = useRef(true);
  const [holder, setDataToHold] = useState({});
  const [journey_state, setJourneyState] = useState(module.state || {});
  const [helper_state, setHelperModalState] = useState({});
  const [ready_state, setReadyState] = useState({});
  const [overlay_state, setOverlayState] = useState(false);
  const [observations, setObservations] = useState({});

  const [activeScreen, setActiveScreen] = useState(0);
  const screen = screenManager(module.common, screens, activeScreen);
  const translation = useTranslationManager(screen.observation, module.locale, translations, { observation: screen.observation, state: journey_state });
  const screen_timers = useScreenTimer(screen.observation);
  const locales = useLanguageSequence(screen.observation, module.locale);
  const screen_views = useScreenViewCounter(screen.observation);
  const buttonStates = useNavigationState(screen, { observations: holder.answer, screen_name: screen.observation, ready_state });
  // const remaining_time = useTimer(screen);

  const handleObservationChange = (value, state = {}) => {
    setDataToHold({
      answer: screen.observation === 'fc_cards_*' ? { ...holder.answer, [value.value.objID]: value } : value,
      state: { ...journey_state, ...state }
    });
  };

  useEffect(() => {
    if (typeof onObservation === 'function') onObservation(observations)
  }, [observations]);

  useEffect(() => {
    if (navigate !== 'reset') {
      handleNavigation(navigate);
      if (typeof onNavigate === 'function') onNavigate();
    }
  }, [navigate]);

  useEffect(() => {
    setDataToHold({
      answer: _get(observations, `${screen.module}.${screen.observation}`),
      state: journey_state
    });
  }, [screen.observation]);

  const handleReady = (observation, state = true) => {
    const newReadyState = { ...ready_state };
    newReadyState[observation] = state;

    setReadyState(newReadyState);
  };

  const handleNavigation = (value, send_observation = false) => {
    let newObservations = observations;
    if (value === 1) {
      newObservations = observationState(screen, observations, holder.answer);
      setObservations(newObservations);
      setJourneyState(holder.state);
    }

    setActiveScreen(activeScreen => {
      const newActiveScreen = nextScreenChecker(value, activeScreen, screens, newObservations, module.skip_keys);
      const is_last_screen = newActiveScreen > screens.length - 1;

      const current_screen_members = screens.map((screen, index) => {
        const _screen = screenManager(module.common, screens, index);

        if (screens[activeScreen].module === _screen.module) return index
      }).filter((idx) => idx !== undefined);

      const is_last_in_module = newActiveScreen > current_screen_members[current_screen_members.length - 1]

      if (send_observation || is_last_screen || is_last_in_module) handleModuleFinish(screen.module, newObservations, is_last_screen);

      if (is_last_screen || newActiveScreen < 0) {
        return activeScreen
      } else {
        screenRef.current = false;
        return newActiveScreen;
      }
    })
  };

  const handleBackNavigation = () => {
    if (buttonStates.back.enabled) {
      handleNavigation(-1)
    }
  };

  const handleForwardNavigation = () => {
    const observation = _get(holder, `answer`);
    const can_navigate_forward = screen.required ? observation !== null : true;
    if (buttonStates.next.enabled && can_navigate_forward) handleNavigation(1)
  };

  const handleModuleFinish = (module_name, observations, finish = false) => {
    if (typeof onModuleFinish === 'function') {
      onModuleFinish(
        combineObservations(
          module_name, module, screens, observations,
          screen_views, screen_timers, helper_state.help_timers,
          locales
        ),
        module_name,
        finish
      );
    }
  };

  const shouldAutoAdvance = (negate_rule = false) => {
    let advance = false;

    // Check auto_advance rule once timer expires
    const auto_advance_type = typeof screen.auto_advance;
    const auto_advance = screen.auto_advance;

    if (auto_advance_type === "boolean" && auto_advance) {
      advance = true
    } else if (auto_advance_type === "object") {
      const rule = RuleManager(auto_advance.rules || [], { observations: _get(holder, `answer`) });
      advance = negate_rule ? !rule : rule;
    }

    return advance
  };

  const handleTimerExpire = () => shouldAutoAdvance() && handleNavigation(1);

  const handleTimerPause = (paused) => setOverlayState(paused);

  const handleAllowTimerExtend = (holder) => {
    const extend = _get(screen, 'timer.extend', false);

    let allowExtend = extend;

    if (extend) {
      allowExtend = shouldAutoAdvance(true);
    }

    return allowExtend
  };

  const setTimerPause = (ready_state) => {
    if (helper_state.open) return true;

    return !RuleManager(_get(screen, 'timer.rules', []), { screen_name: screen.observation, ready_state })
  };

  const calculateProgress = (activeScreen, screens, current_screen) => {
    const target_screens = screens.filter((sc, index) => screenManager(module.common, screens, index).module === current_screen.module)
    let progress_active_screen = activeScreen;

    target_screens.forEach((tsc, idx) => {
      if (current_screen.observation === tsc.observation) progress_active_screen = idx
    })

    return (progress_active_screen / (target_screens.length - 1)) * 100
  }

  return <div>
    <ThemeProvider theme={setTheme(theme || {})}>
      <Overlay show={overlay_state} />
      <LocaleContext.Provider value={translation}>
        <ProgressBar hidden={screen.prograssBar === false ? true : false} progress={calculateProgress(activeScreen, screens, screen)} />
        <CenterContent hidden={_get(screen, "meta.hiddenHeader", false)} center={header && header.center}>
          <Header space={_get(screen, 'timer.visible', false)}>
            <Timer
              timerId={screen.observation}
              time={_get(screen, 'timer.time', 0)}
              timerPaused={setTimerPause(ready_state)}
              allowExtension={handleAllowTimerExtend(holder)}
              enableControlButton={_get(screen, 'timer.pause', false)}
              visible={_get(screen, 'timer.visible', false)}
              extentionPopup={_get(screen, 'timer.extentionPopup', 0)}
              extentionButtonDesign={_get(screen, 'timer.extentionButtonDesign', "false")}
              timerFinishedHandler={handleTimerExpire}
              timerPausedHandler={handleTimerPause}
              timerExtensionCancelledHandler={() => handleNavigation(1)}
              translation={translation}
            />
            <HelperModal
              ready_state={ready_state}
              help={screen.help}
              screen={screen.meta}
              type={screen.type}
              observation={screen.observation}
              journey_state={journey_state}
              helpModalStateHandler={(state) => setHelperModalState(state)}
            />
          </Header>
        </CenterContent>
        <div>
          <Template
            {...screen}
            module={screen.module}
            journey_state={journey_state}
            handleObservationChange={handleObservationChange}
            handleReady={handleReady}
            previousValues={_get(observations, `${screen.module}`, null)}
            value={_get(observations, `${screen.module}.${screen.observation}`, null)}
            translation={translation}
            handleModuleFinish={() => handleNavigation(1, observations)}
            handleNavigation={handleNavigation}
          />
        </div>
        <CenterContent hidden={_get(screen, `meta.hideBottumNavigation`, false)} center={navigation && navigation.center}>
          <Nav>
            <NavButtons visible={buttonStates.back.visible} enabled={buttonStates.back.enabled} onClick={handleBackNavigation}>
              <ArrowLeft />
            </NavButtons>
            <NavButtons visible={buttonStates.next.visible} enabled={buttonStates.next.enabled} onClick={handleForwardNavigation}>
              <ArrowRight />
            </NavButtons>
          </Nav>
        </CenterContent>
      </LocaleContext.Provider>
    </ThemeProvider>
  </div>
};

Player.defaultProps = {
  navigate: 'reset',
  navigation: {
    center: true
  },
  header: {
    center: true
  }
};

export default Player;

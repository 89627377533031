import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GlobalBaseStyle, CenterContent } from '../../assets/styles/BaseStyle';

import { Button, ListStyle } from './TransitionStyle';
import { extract_text } from '../../helpers';

import { LocaleContext } from '../../context/LocalContext';

import _get from 'lodash.get';

const TransitionStart = ({ sub_text, title, sub_title, observation, image, handleNavigation, v2,button_text }) => {
  const translation = useContext(LocaleContext);
  useEffect(()=>getStartTime());

  const setLocalValue = () => {
    getStartTime();
    handleNavigation(1);
  }
  
  const getStartTime = () =>{
    let date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    let stillUtc = moment.utc(date).toDate();
    let local = moment(stillUtc).local().format('hh:mm A');
    localStorage.setItem('startDate', local);
  }

  return (
    <Fragment>
      <GlobalBaseStyle />
      <ListStyle />
      <CenterContent center={true}>
        <div className="row">
          <div className={`col-xs-12 offset-md-12 col-md-12`}>
            <div className={`lefl-module-title moduleTitle`}> {extract_text(title) || _get(translation, `${observation}.title`)} </div>
          </div>
        </div>
        <div style={{ justifyContent: 'center' }} className="row">
          <div className={`col-xs-12 offset-md-12 col-md-12`}>
            <div className={`lefl-module-sub-title moduleSubTitle`}>
              <p> {extract_text(sub_title) || _get(translation, `${observation}.subtitle`)} </p>
              <p> {extract_text(sub_title) || _get(translation, `${observation}.subtitle_2`)} </p>
              <ul className="custom-list">
                <li className="listItemOne">  {extract_text(sub_title) || _get(translation, `${observation}.listItems.itemOne`)} </li>
                <li className="listItemTwo">  {extract_text(sub_title) || _get(translation, `${observation}.listItems.itemTwo`)} </li>
                <li className="listItemThree">  {extract_text(sub_title) || _get(translation, `${observation}.listItems.itemThree`)} </li>
                <li className="listItemFour">  {extract_text(sub_title) || _get(translation, `${observation}.listItems.itemFour`)} </li>
              </ul>
              <p> {extract_text(sub_title) || _get(translation, `${observation}.infoTitle`)} </p>
            </div>
          </div>
          {
            v2 && <Button onClick={() => setLocalValue()}>
              <div>{ extract_text(button_text) || _get(translation, `${observation}.button_text`)}</div>
            </Button>
          }
        </div>
      </CenterContent>
    </Fragment>
  )
};

TransitionStart.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sub_title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  image: PropTypes.string
};

export default TransitionStart;
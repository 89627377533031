const Header = () =>( 
 <>
 <header className="header d-none d-md-flex">
     <div className="container">
         <div className="row"> 
           <div  className="col-12">
               <div className="header-icon">
               <img
                  src='Begini-logo_reverse_rasb-white.png'
                  height='60'
                  alt='Begini'
                />
               </div> 
           </div>
         </div>
     
     </div>
 </header>
 </>
)
export default Header;
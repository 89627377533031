import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import util from 'util';
import _cloneDeep from 'lodash.clonedeep'
import _get from 'lodash.get';

const defaults = {
  time: 0,
  distance: 0,
  direction: [0]
}

const BaseSlider = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);

      const { min, max, defaultValue, value } = props;

      this.prevValue = this.defaultValue;

      this.state = {
        value: _get(value, 'value', this.defaultValue),
        direction: _get(value, 'direction', _cloneDeep(defaults.direction)),
        distance: _get(value, 'distance', defaults.distance),
        time: _get(value, 'time', defaults.time),
        slideStart: null,
        defaultValue: defaultValue || Math.ceil((max - min) / 2)
      }
    }

    sendOtherOptionData = (other_option_data, reset) => {
      this.sendSliderData(other_option_data, reset);
    };

    sendSliderData = (other_option_data, reset = false) => {
      const {onChangeHandler, other_option} = this.props;
      const {value, label, distance, direction, time} = this.state;

      if (util.isFunction(this.props.onChangeHandler)) {
        let sliderData = {
          value,
          time,
          distance,
          direction,
          label
        };

        if (other_option) {
          sliderData.other_option = other_option_data || other_option.defaultValue;
        }

        onChangeHandler(reset ? null : sliderData)
      }
    };

    componentDidUpdate (prevProps) {
      const {observation, value, defaultValue, min, max } = this.props;
      const _defaultValue = defaultValue || Math.ceil((max - min) / 2);

      if (prevProps.observation !== observation) {
        this.setState({
          value: _get(value, 'value', _defaultValue),
          direction: _get(value, 'direction', _cloneDeep(defaults.direction)),
          distance: _get(value, 'distance', defaults.distance),
          time: _get(value, 'time', defaults.time),
          slideStart: null,
          defaultValue: defaultValue || Math.ceil((max - min) / 2)
        })
      }
    }

    onChange = (value, label) => {
      if (!this.state.slideStart) {
        this.setState({ slideStart: new Date().getTime() });
      }

      this.prevValue = this.state.value;

      this.setState({value, label})
    };

    onAfterChange = (value) => {
      if (!this.state.slideStart) {
        return
      }

      const {sendSliderData, prevValue} = this;

      const lastDirection = this.state.direction.slice(-1).pop();
      const dx = value - prevValue;
      let _direction = 0;

      if (dx < 0) {
        _direction = -1
      } else if (dx > 0) {
        _direction = 1
      }

      if (_direction !== 0 && lastDirection !== _direction) {
        this.setState(({ direction }) => {
          direction.push(_direction);

          return {
            direction
          }
        })
      }

      this.setState(({ time, distance, slideStart }) => {
        time += new Date().getTime() - slideStart;
        distance += Math.abs(dx);

        return {
          time,
          distance,
          slideStart: null
        }
      });

      sendSliderData()
    };

    render() {

      const { value, defaultValue, min, max } = this.props;

      return <WrappedComponent
        {...this.props}
        value={_get(value, 'value')}
        otherOptionValue={_get(value, 'other_option')}
        defaultValue={defaultValue || Math.ceil((max - min) / 2)}
        onAfterChange={this.onAfterChange}
        onChange={this.onChange}
        sendOtherOptionData={this.sendOtherOptionData}
      />
    }
  }
};

export default BaseSlider

BaseSlider.defaultProps = {
	step: 1,
	min: 0,
	max: 10,
	prefix: '',
	suffix: '',
	time: 0,
	distance: 0,
  title: '',
  sub_title: ''
};

BaseSlider.proptypes = {
	value: PropTypes.object,
	defaultValue: PropTypes.number.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	prefix: PropTypes.string,
	suffix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]),
	time: PropTypes.number,
	distance: PropTypes.number,
	direction: PropTypes.array,
	onChangeHandler: PropTypes.func
};

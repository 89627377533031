
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import * as dateTime from '../../../src/lib/date-time'
import Select from '../Select/Select'
import { DateContainer } from './DateSelectorStyles';

const DateSelector = (props) => {
  const [selMonth, setMonthSelected] = useState('');
  const [selYear, setYearSelected]   = useState('');

  useEffect(() => {
    if(props.previousValues) {
      let preOb =  `${props.previousValues[props.observation]}`;
      if(preOb !== 'undefined') {
          let prMonth = `${props.previousValues[props.observation].month}`;
          let prYear = `${props.previousValues[props.observation].year}`;
          setMonthSelected(prMonth);
          setYearSelected(prYear);
          //  setSelected();
      } 
    }
  }, [])

  const componentRef = useRef(true);
  const dateFormat = props.format || 'YYYY-MM-DD';
  const displayOrder = props.display_order || dateFormat;
  const seperator = dateFormat.indexOf('-') ? '-' : dateFormat.indexOf('/') ? '/' : ' ';
  const fields = displayOrder.split(seperator);

  const currentDate = moment();
  const dateDiff = {
    unit: 'year',
    value: 100
  };

  const getDateValues = (value) => {
    const dateObject = moment(value, dateFormat);

    return {
      day: dateObject.date() || null,
      month: (dateObject.month() + 1) || null,
      year: dateObject.year() || null
    }
  };

  const getDateOptions = () => {
    const { min, max } = props;
    const { dayOptions, monthOptions } = dateTime.dayMonthOptions();
    let yearOptions = [];

    let maxDate = moment(max, dateFormat);
    if (!maxDate.isValid()) {
      maxDate = currentDate.clone();
    }

    let minDate = moment(min, dateFormat);
    if (!minDate.isValid()) {
      minDate = maxDate.clone();
      minDate.subtract(dateDiff.value, dateDiff.unit);
    }

    const minYear = minDate.year();
    const maxYear = maxDate.year();

    for (let x = maxYear; x >= minYear; x--) {
      yearOptions.push({
        value: x,
        label: x
      })
    }

    return {
      day: dayOptions,
      month: monthOptions,
      year: yearOptions
    }
  };

  const options = getDateOptions();
  const dateValues = getDateValues(props.value.date || props.defaultValue);

  const [day, setDay] = useState(dateValues.day || 1);
  const [month, setMonth] = useState(dateValues.month);
  const [year, setYear] = useState(dateValues.year);

  useEffect(() => {
    const mainResponseHandler = () => {
      const { onChangeHandler, min, max } = props;
      const date = `${year}-${month}-${day}`;
      const dateObject = moment(date, dateFormat);

      const response = {
        day,
        month,
        year,
      };

      const validDate = dateObject.isValid();
      const allFieldsSet = year && month && day;

      if (allFieldsSet) {
        response.validationCode = dateTime.validate({
          min,
          max,
          value: date
        })
      }

      if (allFieldsSet && validDate) {
        response.date = dateObject.format(dateFormat);
        if (typeof onChangeHandler === 'function') onChangeHandler(response)
      } else {
        if (typeof onChangeHandler === 'function') onChangeHandler(null)
      }
    };

    if (!componentRef.current) {
      mainResponseHandler()
    } else {
      componentRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year]);

  const dayState = {
    label: props.labels.day,
    options: options.day,
    isOpen: props.isOpen,
    value: day,
    handPointer: props.handPointer && props.handTarget === 'day',
    hide_hands: props.hide_hands,
    responseHandler: (resp) => fieldResponseHandler('day', resp)
  };

  const monthState = {
    label: props.labels.month,
    options: options.month,
    selectedVal: selMonth,
    value: month,
    isOpen: props.isOpen,
    handPointer: props.handPointer && props.handTarget === 'month',
    responseHandler: (resp) => fieldResponseHandler('month', resp)
  };

  const yearState = {
    label: props.labels.year,
    selectedVal: selYear,
    options: options.year,
    value: year,
    handPointer: props.handPointer && props.handTarget === 'year',
    responseHandler: (resp) => fieldResponseHandler('year', resp)
  };



  const selectors = [];

  fields.forEach(field => {
    if (/dd|DD/.test(field)) selectors.push(dayState);
    if (/mm|MM/.test(field)) selectors.push(monthState);
    if (/yy|YY|YYYY/.test(field)) selectors.push(yearState);
  });
  selectors.reverse();
  
  const fieldResponseHandler = (field, resp) => {
    // eslint-disable-next-line default-case
    switch (field) {
      case 'day':
        setDay(resp.value);
        break;
      case 'month':
        setMonth(resp.value);
        break;
      case 'year':
        setYear(resp.value);
        break;
    }
  };

  
  return <div className={`container-fluid`}>
    <div className={`row`}>
      {
        selectors.map((selector, index) => {
          return <div key={index} className={`col-12 col-sm-${12 / selectors.length}`}>
            <DateContainer className="form-group field-day" pointer={props.handPointer}>
              <Select pointerHelp={props.handPointer} v2={props.v2} help={props.help} {...selector} />
            </DateContainer>
          </div>
        })
      }
    </div>
  </div>
};

export default DateSelector

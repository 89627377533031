import { TextDiv } from "./style"

import {loadImage} from '../../helpers/image_loader';
const FireImage = ({ image, text,type }) => {
  debugger
  return (<TextDiv type={type}>
    <div className="superMain">
      <div className="subImdTxt">
        <p><span className="apOne">{text&& text.imgText1}</span>
          <span className="apTwo">{text&& text.imgText2}</span>
          <span className="apThree">{text&&text.imgText3}</span>
          <span className="apFour">{text&&text.imgText4}</span>
        </p>
      </div>
      <img src={loadImage(image)} className="center" alt="game_image" />
    </div>
  </TextDiv>)
}
export default FireImage
import React, { createContext } from 'react'

import en from '../locale/en.json';
import am from '../locale/am.json';
import om from '../locale/om.json';
import fr from '../locale/fr.json';

export const translations = {
  en,
  am,
  om,
  fr
};

export const LocaleContext = createContext({});

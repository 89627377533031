import React, { useContext,useState,useEffect } from "react";

import { GlobalBaseStyle, CenterContent,Question } from "../../assets/styles/BaseStyle";
import {GameOptionButton,GameImage,ADDEDFIRE,ExitButtons, TextDiv} from "./FireBuildGameStyle"
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";
import { loadImage } from "../../helpers/image_loader";
import _get from "lodash.get";

const FireBuildGame =({observation,title,title_2,image,option, onChangeHandler, handleNavigation,generate,button_text}) =>{
    const translation = useContext(LocaleContext);
    const [campFire,setCampFire] = useState(image);
    const [fireAdded,setFireAdded] = useState(false);
    const [fireAddedImage,setFireAddedImage] = useState()
    const [firePercentage,setFirePercentage] = useState(0);
    const [woods,selectedWoods] = useState([]);
    const [exitGame,setExit] = useState();

    useEffect(()=>{
      if (typeof onChangeHandler === "function") {
        const generateScreenTimeout = _get(generate, 'delay', 5000);
        if(firePercentage  === 0){
            setTimeout(() => {
              onChangeHandler({ 
                value: 'auto',
                label: 'fire',
                noaction: firePercentage === 0,
                timesfilled: woods.length,
                final:`${firePercentage}%`,
                selection: woods
                });
            }, generateScreenTimeout);
          }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(()=>{
      if (exitGame && typeof handleNavigation === "function") {
        handleNavigation(1);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[exitGame]);

    useEffect(()=>{ 
        if(firePercentage > 0 && firePercentage < 200){
          
          let camp = Math.round((firePercentage/2)*.37);
          setCampFire(`fire_build_game/fireFN${camp}`);
          setTimeout(()=> setFireAdded(false),1000);
          if (typeof onChangeHandler === "function") {
          onChangeHandler({ 
            value: 'fire',
            label: 'fire',
            noaction: firePercentage === 0,
            timesfilled: woods.length,
            final:`${firePercentage}%`,
            selection: woods
            });
          }
        }else if(firePercentage >= 200){
          if (typeof onChangeHandler === "function") {
            onChangeHandler({ 
              value: 'exceed',
              label: 'fire',
              noaction: firePercentage === 0,
              timesfilled: woods.length,
              final:`${firePercentage}%`,
              selection: woods
              });

           setExit(true)
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[firePercentage]);

    const addFire = (percentage,type) =>{
        let fire = firePercentage + percentage
        setFirePercentage(fire);
        setFireAddedImage(type)
        setFireAdded(true);
        selectedWoods([...woods,type])
        

    }
    const donePlaying = () =>{
        if (typeof onChangeHandler === "function") {
           onChangeHandler({ 
             value: 'done',
             label: 'fire',
             noaction: firePercentage === 0,
             timesfilled: woods.length,
             final:`${firePercentage}%`,
             selection: woods
             });
           setExit(true)
        }
    }
    return(<>
      <GlobalBaseStyle />
      <CenterContent center={true}>
      <Question alignment={_get(title, "alignment", "center")}>
          {extract_text(title) ||
            _get(
              translation,
              `${observation}.title`
            )}
        </Question>
        <GameImage>
          <TextDiv>
        <div className="superMain">
          <div className="subImdTxt">
            <p><span className="apOne">{_get( translation, `${observation}.imgText1`)}</span>
               <span className="apTwo">{_get( translation, `${observation}.imgText2`)}</span>
               <span className="apThree">{_get( translation, `${observation}.imgText3`)}</span>
              <span className="apFour">{_get( translation, `${observation}.imgText4`)}</span>
            </p>
          </div>
          <img src={loadImage(campFire)} className="center" alt="game_image"/>
          {fireAdded && <ADDEDFIRE>
            <Icon text={_get(
                translation,
                `${observation}.${fireAddedImage}`
              )}/>  </ADDEDFIRE>}
        </div>
        </TextDiv>
        </GameImage>
        <div  className="row">
            <div className="col-6">
                <GameOptionButton onClick={()=>addFire(5,'twig_added')}>
                 <img src={loadImage(option[0])} className="center" alt="game_option_1"/>
                 <button className="button_otp"> {_get(
              translation,
              `${observation}.option_1`,'Add a twig')}</button>
                </GameOptionButton>
            </div>
            <div className="col-6">
            <GameOptionButton  onClick={()=>addFire(20,'branch_added')}>
            <img src={loadImage(option[1])} className="center" alt="game_option_2"/>
            <button className="button_otp"> {_get(
              translation,
              `${observation}.option_2`,'Add a branch')}</button>
                </GameOptionButton>
            </div>
            <div className="col-6">
                <GameOptionButton  onClick={()=>addFire(35,'logs_added')}>
            <img src={loadImage(option[2])} className="center" alt="game_option_3"/>
            <button className="button_otp"> {_get(
              translation,
              `${observation}.option_3`,'Add logs')} </button>
            </GameOptionButton>
            </div>
            <div className="col-6">
            <GameOptionButton  onClick={()=>addFire(50,'large_log_added')}>
            <img src={loadImage(option[3])} className="center" alt="game_option_4"/>
            <button className="button_otp"> {_get(
              translation,
              `${observation}.option_4`,'Add large log')}</button>
            </GameOptionButton>
            </div>
            <div className="col-12">
            <ExitButtons  onClick={donePlaying}>
            {extract_text(button_text) ||
            _get(
              translation,
              `${observation}.button_text`
            )}
            </ExitButtons>
            </div>
        </div>
      </CenterContent>
    </>)
}
function Icon({text}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="149"
      height="50.975"
      viewBox="0 0 149 50.975"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#c20e1a"></stop>
          <stop offset="0.378" stopColor="#c20e1a"></stop>
          <stop offset="0.565" stopColor="#cf621c"></stop>
          <stop offset="0.654" stopColor="#d77f1b"></stop>
          <stop offset="0.807" stopColor="#db951c"></stop>
          <stop offset="1" stopColor="#d3a121"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 68173" transform="translate(11839 12092.975)">
        <rect
          width="149"
          height="27"
          fill="#c2ceda"
          data-name="Rectangle 5002"
          rx="6"
          transform="translate(-11839 -12069)"
        ></rect>
        <g data-name="Group 68172">
          <g
            stroke="#222221"
            data-name="Group 68170"
            transform="translate(-11833.043 -12067.007)"
          >
            <g
              fill="#7f735c"
              strokeWidth="1"
              data-name="Rectangle 5001"
              transform="rotate(-146 15.547 7.913)"
            >
              <rect width="28.963" height="8.409" stroke="none" rx="3"></rect>
              <rect
                width="27.963"
                height="7.409"
                x="0.5"
                y="0.5"
                fill="none"
                rx="2.5"
              ></rect>
            </g>
            <path
              fill="none"
              strokeWidth="0.5"
              d="M0 0L19.62 13.08"
              data-name="Line 42"
              transform="translate(4.546 5.043)"
            ></path>
          </g>
          <path
            fill="url(#linear-gradient)"
            d="M-11826.049-12189.577s-2.673 3.741-1.28 7.4a10.48 10.48 0 004.931 5.279s-.259-3.794.366-5.534a4.971 4.971 0 012.276-2.544 10.876 10.876 0 00-.491 3.124 3.631 3.631 0 00.8 2.1 7.181 7.181 0 002.32-3.927 22.013 22.013 0 000-6.472 11.718 11.718 0 012.1 6.472c-.179 3.313-3.253 7.8-3.253 7.8a11.443 11.443 0 001.922 0 7.537 7.537 0 001.648-.378 6.373 6.373 0 001.339-2.266 9.082 9.082 0 00.266-2.926s.51.233.756 1.517a6.868 6.868 0 01-.42 3.035s5.211-3.24 5.535-8.23-3.761-7.9-6.137-11.2c-1.224-1.7-1.339-5.476-1.339-5.476s-5.64 2.075-7.324 7.075-.044 6.409-1.428 6.9-2.587-1.749-2.587-1.749z"
            data-name="Path 80640"
            transform="translate(-.83 108.836)"
          ></path>
          <path
            fill="#c20e1a"
            d="M-11824.269-12189.958a2.6 2.6 0 01-.958-3.591 10.244 10.244 0 001.429-3.1 6.458 6.458 0 011.049 3.583 3.756 3.756 0 01-1.52 3.108z"
            data-name="Path 80641"
            transform="translate(-1.319 107.836)"
          ></path>
          <g
            stroke="#222221"
            data-name="Group 68171"
            transform="translate(-11833.043 -12067.007)"
          >
            <g
              fill="#7f735c"
              strokeWidth="1"
              data-name="Rectangle 5001"
              transform="rotate(-34 26.487 8.098)"
            >
              <rect width="28.963" height="8.408" stroke="none" rx="3"></rect>
              <rect
                width="27.963"
                height="7.408"
                x="0.5"
                y="0.5"
                fill="none"
                rx="2.5"
              ></rect>
            </g>
            <path
              fill="none"
              strokeWidth="0.5"
              d="M19.62 0L0 13.08"
              data-name="Line 42"
              transform="translate(4.547 5.043)"
            ></path>
          </g>
        </g>
        <text
          data-name="Log Added"
          fontFamily="Roboto-Regular, Roboto"
          fontSize="14"
          opacity="0.72"
          transform="translate(-11795 -12051)"
        >
          <tspan x="0" y="0" className="text_added">
            {text}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default FireBuildGame;
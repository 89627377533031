// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'cgp_intro',
            type: 'intro',
            module: 'coins_personal',
            meta: {
                v2: true,
                hiddenHeader: true,
                hideBottumNavigation: true,
                image: 'coinGames/newIntro',
                sub_text: true,
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'cgp_exercise',
            module: 'coins_personal',
            type: 'coin_game',
            help: true,
            timer: {
                time: 60000,
                extend: true,
                pause: true,
                visible: true,
                extentionPopup: 1,
                extentionButtonDesign: "true"
            },
            back: false,
            meta: {
                coinImg: ['coinGames/active_coin', 'coinGames/used_coin'],
                fields: ['Food', 'Transport', 'Communication (airtime, internet)', 'Water/Electricity', 'Entertainment', 'Clothing', 'Family support', 'Debt/Loans', 'Health', 'Savings', 'School fees', 'Rent/Mortgage', 'Insurance', 'Home improvement', 'Others'],
                totleCoins: Array.from({ length: 20 }, () => true),
                helpNavigation: true,
                title: { alignment: 'left' },
                hideHalpNavigation: true,
                helpImgs: ['coinGames/cgp_help1','coinGames/cgp_help2']
            }
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "coins_personal",
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ]
}
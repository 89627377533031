import styled from "styled-components";
export const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;  
  img{
      max-width: 320px;
      width:100%;
      margin:0px auto; 
      @media (max-width: 768px) {
        width:100%;
      }
  }
  .superMain {
    position: relative;
  }
  .subImdTxt {
    position: absolute;
    font-size: 9px;
    ${({ type }) => type === 'intro'  ? `
    top: 3px;
    left: 21px;
    right: 78px;`: `
    top: 52px;
    left: 30px;
    right: 30px;`}
    p{
      display:flex;
      .apTwo {
        margin-left: auto;
      }
      .apThree {
        margin-left: auto;
        margin-right:5px;
      }
    }
  }
 
  @media (max-width: 350px) {
    .subImdTxt {
      ${({ type }) => type === 'intro'  ? `
      top: 3px;
      left: 21px;
      right: 76px;`:
      `top: 48px;`}
      font-size: 8px;
    }
  }
`;
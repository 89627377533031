import React from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {  NotFound } from './pages/index';
import Header from "./components/Header";
import Uncdf from "./components/Uncdf";
const Routing = () => {
  return (
    <>
      <Router>
        <Header />
        <Container maxWidth="lg">
          <Switch>
            <Route exact path="/" component={() => (<Uncdf />)}  />
            <Route path=""   component={NotFound}/>
          </Switch>
        </Container>
      </Router>
    </>
  );
};

export default Routing;

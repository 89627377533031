import React, { Fragment, useContext, useEffect, useState } from "react";

import { GlobalBaseStyle } from "../../assets/styles/BaseStyle";
import { CenteredText, BoxContainer, Option } from "./ChoiceBoxStyle";
import { extract_text } from "../../helpers";

import getSymbolFromCurrency from "currency-symbol-map";
import { LocaleContext } from "../../context/LocalContext";
import _get from "lodash.get";

import numeral from "numeral";

const ChoiceBoxScreen = ({
  observation, title, sub_title, label_1, label_2, suffix, prefix, value_1, value_2, generate,
  onChangeHandler, handleNavigation, previousValues, handPointer, is_first, v2
}) => {
  const [clicked, setClicked] = useState(false);
  const [factorA, setSactorA] = useState(value_1);
  const translation = useContext(LocaleContext);
  useEffect(() => {
    const generateScreenTimeout = _get(generate, "delay");
    const ob = _get(generate, "prev_ob");
    const prevSelect = _get(previousValues, ob, "");
    const del = _get(generate, "delta", 0);
    const { v1 } = choiceFormula(
      del,
      parseInt(factorA),
      parseInt(value_2),
      _get(prevSelect, "label")
    );
    setSactorA(v1);
    setClicked(false);
    if (typeof generateScreenTimeout === "number") {
      setTimeout(() => {
        if (!clicked && !handPointer) onChangeHandler({ value: factorA, label: _get(translation, `${observation}.label_1`) || `option_1` });
      }, generateScreenTimeout);
    }
  }, [observation]);

  useEffect(() => {
    if (clicked) handleNavigation(1);
  }, [clicked]);

  const applyFormatting = (value) => {
    const evaluatedSuffix =
      typeof suffix === "function" ? suffix(value) : suffix;
    const formattedValue = numeral(value).format(`0,0`);

    let formatting = "";

    if (prefix) formatting += `${getSymbolFromCurrency(prefix) || prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;
    return formatting;
  };

  const choiceMade = (v, l) => {
    if (typeof onChangeHandler === "function") {
      setClicked(true);
      onChangeHandler({ value: v, label: l });
    }
  };

  const choiceFormula = (d, v_1, v_2, select) => {
    if (d === 0) {
      return { v1: value_1, v2: v_2 };
    }
    if (select === "option_1") {
      return { v1: v_1 - d, v2: v_2 };
    } else {
      return { v1: v_1 + d, v2: v_2 };
    }
  };

  return (
    <Fragment>
      <GlobalBaseStyle />
      <div className="row">
        <div className="col-12">
          <CenteredText>
            {extract_text(title) || _get(translation, `${observation}.title`)}
          </CenteredText>
        </div>
        <div className="col-12">
          <div className={`text-center`}>
            {extract_text(sub_title) ||
              _get(translation, `${observation}.sub_title`)}
          </div>
        </div>
        <div className="col-12">
          <BoxContainer>
            <Option pointer={is_first && handPointer} onClick={() => handPointer ||
              choiceMade(factorA, _get(translation, `${observation}.label_1`))}>
              <Icon value={applyFormatting(factorA)} />
              <span>
                {extract_text(label_1) ||
                  _get(translation, `${observation}.label_1`, 'option_1')}
              </span>
            </Option>
            <Option pointer={!is_first && handPointer} onClick={() => handPointer ||
              choiceMade(value_2, _get(translation, `${observation}.label_2`, 'option_2'))}>
              <Icon value={applyFormatting(value_2)} />
              <span>
                {extract_text(label_2) ||
                  _get(translation, `${observation}.label_2`)}
              </span>
            </Option>
          </BoxContainer>
        </div>
      </div>
      <div className="row"></div>
    </Fragment>
  );
};
ChoiceBoxScreen.defaultProps = {
  prefix: "USD",
  suffix: "",
};
const Icon = ({ value }) => (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 117.5 63">
    <path fill="#090" d="M115.2 63H2.2V0h113v63zm-111-2h109V2H4.2v59z"></path>
    <path fill="#090" d="M109.2 56.8H20.4v-.5c0-6-4.9-10.9-10.9-10.9v-1c6.4 0 11.6 5.1 11.9 11.4h87.8v1zm0-31.3h-1v-6.8C102 18.4 97 13.5 96.8 7.3H41v-1h56.8v.5c0 6 4.9 10.9 10.9 10.9h.5v7.8zM9.5 6.3h23.2v1H9.5zM9.5 9.8h23.2v1H9.5zM92.7 48.4h16.6v1H92.7zM92.7 51.9h16.6v1H92.7z"></path>
    <text x="50%" y="38" textAnchor="middle">{value}</text>
  </svg>
);

export default ChoiceBoxScreen;

'use strict'

import React, { Component, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import util from 'util'

import { ImageButton, Item } from './GroupImageButtonStyles';
import { Question, Hint, CenterContent, GlobalBaseStyle } from '../../assets/styles/BaseStyle';
import { extract_text } from '../../helpers';
import { loadImage } from '../../helpers/image_loader'
import _get from 'lodash.get'


class GroupImageButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			valueSequence: [],
			labelSequence: [],
			responseSequence: []
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		const newState = {};
		let { selectedOptionValue, options } = this.props;
		for (let x in options) {
			if (options[x].value === selectedOptionValue) {
				break;
			}
		}
		this.setState(newState)
	}

	handleOptionChange = (index) => {
		const { valueSequence, labelSequence, responseSequence } = this.state;
		const { options } = this.props
		if (valueSequence.includes(index + 1)) {
			this.setState({
				valueSequence: [...valueSequence.filter((i) => i !== index + 1)],
				labelSequence: [...labelSequence.filter((val) => val !== options[index].label)],
				responseSequence: [...responseSequence, -(index + 1)]
			}, () => {
				if (util.isFunction(this.props.onChangeHandler)) {
					this.props.onChangeHandler({
						label: this.state.labelSequence,
						value: this.state.valueSequence,
						sequence: this.state.responseSequence,
						itsDone: this.state.valueSequence.length > 0
					})
				}
			});
		}
		else
			this.setState({
				valueSequence: [...valueSequence, index + 1],
				labelSequence: [...labelSequence, options[index].label],
				responseSequence: [...responseSequence, index + 1]
			}, () => {
				if (util.isFunction(this.props.onChangeHandler)) {
					this.props.onChangeHandler({
						label: this.state.labelSequence,
						value: this.state.valueSequence,
						sequence: this.state.responseSequence,
						itsDone: this.state.valueSequence.length > 0
					})
				}
			})
	}

	generateOptions = () => {
		let { options, observation } = this.props;
		let answerOptions = [];
		options.forEach((option, i) => {
			const answerOption = <Item selected={this.state.valueSequence.includes(i + 1)} key={i} onClick={() => this.handleOptionChange(i)}>
				{option.image ? <img src={loadImage(option.image)} /> : null}
				<div>{_get(this.props, `translation[${observation}][option${1 + i}]`, '')}</div>
			</Item>;
			answerOptions.push(answerOption)
		})

		return answerOptions
	}
	render() {
		let { options, observation } = this.props;
		const { title, sub_title, center } = this.props;
		return (
			<CenterContent center={center}>
				<GlobalBaseStyle />
				<div>
					<Question alignment={title?.alignment}>{_get(this.props.translation,`${observation}.title`,extract_text(title))}</Question>
					<Hint visible={sub_title} render_as={sub_title.as} alignment={sub_title.alignment}>{extract_text(sub_title)}</Hint>
				</div>
				<ImageButton>
					{this.generateOptions()}
				</ImageButton>
			</CenterContent>
		)
	}
}

GroupImageButton.defaultProps = {
	title: {},
	sub_title: {},
	options: [],
	center: true
};

GroupImageButton.propTypes = {
	title: PropTypes.object.isRequired,
	sub_title: PropTypes.object,
	options: PropTypes.array.isRequired,
	onChangeHandler: PropTypes.func,
	center: PropTypes.bool,
};

export default GroupImageButton;

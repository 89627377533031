export default {
    screens: [
        {
            observation: 'cgb_intro',
            type: 'intro',
            module: 'coins_business',
            meta: {
                v2: true,
                hiddenHeader: true,
                hideBottumNavigation: true,
                image: 'coinGames/cgb_intro',
                sub_text: true,
            },
            auto_advance: true,
            timer: {
                time: 5000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'cgb_exercise',
            module: 'coins_business',
            type: 'coin_game',
            help: true,
            auto_advance: {
                rules: ['itsDone']
            },
            timer: {
                time: 180000,
                extend: true,
                pause: true,
                visible: true,
                extentionPopup: 1,
                extentionButtonDesign: "true"
            },
            back: false,
            meta: {
                coinImg: ['coinGames/active_coin', 'coinGames/used_coin'],
                fields: ['Stock', 'Equipment', 'Transport', 'Communication (airtime, internet)', 'Insurance', 'Water / Electricity', 'Supplier payments', 'Employee payments', 'Maintenance', 'Debt / Loan payments', 'Investment', 'Savings', 'Rent / Mortgage'],
                totleCoins: Array.from({ length: 20 }, () => true),
                helpNavigation: true,
                title: { alignment: 'left' },
                hideHalpNavigation: true,
                helpImgs: ['coinGames/cgp_help1']
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['itsDone']
                },
                visible: true,
                enabled: false
            },
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "coins_business",
        }
    ]
}
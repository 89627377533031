
import React, { useState, useEffect, useContext, Fragment } from 'react';
import ImageSlider from '../../templates/ImageSlider/ImageSlider'
import NumericalSlider from '../../templates/NumericalSlider/NumericalSlider'
import TooltipSlider from '../../templates/TooltipSlider/TooltipSlider'
import SimpleSlider from '../../templates/SimpleSlider/SimpleSlider'
import GroupRadioButton from '../../templates/GroupRadioButton/GroupRadioButton'
import GroupCheckboxButton from '../../templates/GroupCheckboxButton/GroupCheckboxButton'
import ChoiceBox from '../../templates/ChoiceBox/ChoiceBox';
import DecisionMake from '../../templates/DecisionMaking/DecisionMake'

import TextInput from '../../templates/TextInput/TextInput'
import ComboBox from '../../templates/ComboBox/ComboBox'
import { RuleManager,useHelpModalTimerReducer } from '../../hooks';

import { Header, Footer, Buttons, Body, NextButton, PreviousButton, Trigger, ModalContainer, Instructions, ImageContainer } from './HelpModalStyles';
import { instruction_translations } from '../../helpers/modal_instructions';
import { LocaleContext } from '../../context/LocalContext';
import _get from 'lodash.get';
import GroupRadioButtonImage from '../../templates/GroupRadioButtonImage/GroupRadioButtonImage';
import { loadImage } from "../../helpers/image_loader";


import GroupRadioRating from '../../templates/GroupRadioRating/GroupRadioRating';
import EndIntro from '../../templates/TransitionEnd/EndIntro';
import ConfidentialityCheck from '../../templates/ConfidentialityCheck/ConfidentialityCheck';
import FillAmount from '../../templates/FillAmount/FillAmount';

const recipeManager = ({ type, screen, observation, journey_state, translation }) => {
	// for deep copy
	const screenClone = JSON.stringify(screen);
	const screens = [];
	const slider_target = 'defaultValue';
	// eslint-disable-next-line default-case
	switch (type) {
		case 'image_slider':
			const image_slider_fake_data = [screen[slider_target], (screen.max * .3), (screen.max * .1)];

			image_slider_fake_data.forEach((fake, index) => {
				const fake_props = {
					...screen,
					[slider_target]: fake,
					handPointer: true,
					value: {}
				};

				screens.push(<ImageSlider key={index} {...fake_props} observation={observation} journey_state={journey_state} />)
			});

			break;
		case 'simple_slider':
			const simple_slider_fake_data = [screen[slider_target], (screen.max * .2), (screen.max * .8)];

			simple_slider_fake_data.forEach((fake, index) => {
				const fake_props = {
					...screen,
					[slider_target]: fake,
					handPointer: true,
					value: {}
				};

				screens.push(<SimpleSlider key={index} {...fake_props} observation={observation} />)
			});

			break;
		case 'numerical_slider':
			const numerical_slider_fake_data = [screen[slider_target], (screen.max * .2), (screen.max * .8)];

			numerical_slider_fake_data.forEach((fake, index) => {
				const fake_props = {
					...screen,
					[slider_target]: fake,
					handPointer: true,
					value: {}
				};

				screens.push(<NumericalSlider help={true} key={index} {...fake_props} observation={observation} />)
			});

			break;
		case 'tooltip_slider':
			const tooltip_slider_fake_data = [screen[slider_target], (screen.max * .7), (screen.max * 1.0)];

			tooltip_slider_fake_data.forEach((fake, index) => {
				const fake_props = {
					...screen,
					[slider_target]: fake,
					handPointer: true,
					value: {}
				};

				screens.push(<TooltipSlider key={index} {...fake_props} observation={observation} />)
			});

			break;
		case 'checkbox_group':
			const group_checkbox_fake_options = screen.options.slice(0, 3);
			const group_checkbox_fake_data = group_checkbox_fake_options.map(option => {
				return option.value
			});

			// We only need 2 as sample
			group_checkbox_fake_data.forEach((fake, index) => {
				let defaultValue = [];
				let hide_hands = false;
				let handTarget = null;

				if (index === 0) {
					defaultValue = [fake];
					handTarget = fake
				}

				if (index === 1) {
					defaultValue = group_checkbox_fake_data.slice(0, 2);
					hide_hands = true
				}

				if (index === 2) {
					defaultValue = group_checkbox_fake_data.slice(1, 2);
					handTarget = group_checkbox_fake_data[0];
				}

				const fake_props = {
					...screen,
					options: group_checkbox_fake_options,
					defaultValue,
					handPointer: true,
					hide_hands,
					handTarget,
					value: {}
				};

				screens.push(<GroupCheckboxButton key={index} {...fake_props} observation={observation} />)
			});

			break;
		case 'radio_group':
			const { options } = screen;
			const group_radio_fake_options = options.slice(0, 3);
			const group_radio_fake_data = group_radio_fake_options.map(option => {
				
				return option.value
			});
			// We only need 2 as sample
			group_radio_fake_data.slice(0, 2).forEach((fake, index) => {
				const fake_props = {
					...screen,
					options: group_radio_fake_options,
					defaultValue: fake,
					handPointer: true
				};

				screens.push(<GroupRadioButton key={index} {...fake_props} observation={observation} />)
			});

			break;
		case 'digit_span':
			screens.push(<TextInput key={0} center={true} questions={[{ handPointer: true }]} />);
			screens.push(<TextInput key={1} center={true} questions={[{ handPointer: true, defaultValue: 9999, hide_hands: true }]} />);
			break;
		case 'text_input':
			const text_input_fake_screen1 = JSON.parse(screenClone);
			const text_input_fake_questions1 = text_input_fake_screen1.questions.slice(0, 1);

			const text_input_fake_data1 = text_input_fake_questions1.map(question => {
				question.handPointer = true;
				question.value = {};

				return question
			});

			// Copy questions of original screen so we can inject fake data as result
			const text_input_fake_screen2 = JSON.parse(screenClone);
			const text_input_fake_questions2 = text_input_fake_screen2.questions.slice(0, 1);

			const text_input_fake_data2 = text_input_fake_questions2.map(question => {
				const defaultFakeData = question.data_type === 'number' ? 999999 : 'Pancakes';
				question.defaultValue = question.sample_answer || defaultFakeData;
				question.handPointer = true;
				question.hide_hands = true;
				question.value = {};

				return question
			});

			// Now create the fake screens with fake input
			text_input_fake_screen1.questions = text_input_fake_data1;
			text_input_fake_screen2.questions = text_input_fake_data2;

			screens.push(<TextInput key={0} {...text_input_fake_screen1} />);
			screens.push(<TextInput key={1} {...text_input_fake_screen2} />);
			break;
		case 'combo_box':
			if (screen.preset && screen.preset.type === 'date_picker') {
				screens.push(<ComboBox {...screen} help={true} value={{}} handPointer={true} observation={observation} helpText={'helpText1'} handTarget={'month'} />);
				screens.push(<ComboBox {...screen} help={true} value={{}} handPointer={true} observation={observation} helpText={'helpText2'} isOpen={true} />);
				screens.push(<ComboBox {...screen} help={true} value={{}} handPointer={true} observation={observation} helpText={'helpText3'} handTarget={'year'} />);
			}
			break;
		case "radio_group_image":

			const group_radio_image_fake_options = screen.options.slice(0, 3);
			const group_radio_image_fake_data = group_radio_image_fake_options.map(option => {
				return option.value
			});
			group_radio_image_fake_data.slice(0, 2).forEach((fake, index) => {
				const fake_props = {
					...screen,
					options: group_radio_image_fake_options,
					defaultValue: fake,
					handPointer: true
				};

				screens.push(<GroupRadioButtonImage key={index} {...fake_props} observation={observation} />)
			});
			break;
		case 'fin_choice':
			const choice_box_fake_options = [{ ...screen, value: screen.value_1, value_1: undefined }, { ...screen, value: screen.value_2, value_2: undefined }]
			const choice_box_fake_fake_data = choice_box_fake_options.map(option => option.value);
			choice_box_fake_fake_data.forEach((fake, index) => {
				const fake_props = {
					...screen,
					options: fake,
					defaultValue: fake,
					is_first: index === 0 ? true : false,
					handPointer: true
				};

				screens.push(<ChoiceBox {...screen} {...fake_props} observation={observation} />)
			});
			break;
		case 'find_pattern':
			const helpImg = _get(screen, 'helpImgs', null);
			
					helpImg.map((img, i) => {
						screens.push (<ImageContainer key={i}>
							{ _get(translation, `${observation}.help_modal.helpTxt`)}
							<img src={loadImage(img)} alt={observation} />
						</ImageContainer>);
					})
			break;
		case 'fire_building':
			const fireImg = _get(screen, 'helpImgs', null);
			
					fireImg.map((img, i) => {
						screens.push (<ImageContainer key={i} stage={i}>
							<p className="pl-3 pr-3">{ _get(translation, `${observation}.helpTxt${i + 1}`)}</p>
							<div className="image-content">
								<img src={loadImage(img)} alt={observation} />
								<div className="subImdTxt">
									<p>
										<span className="apOne">{_get( translation, `${observation}.imgText1`)}</span>
										<span className="apTwo">{_get( translation, `${observation}.imgText2`)}</span>
										<span className="apThree">{_get( translation, `${observation}.imgText3`)}</span>
										<span className="apFour">{_get( translation, `${observation}.imgText4`)}</span>
									</p>
								</div>
								{i ===1 && <div className="fire_txt">{_get( translation, `${observation}.branch_added`)}</div>}
							  </div>
						</ImageContainer>);
					})
			break;
		case 'pick_img':

			screens.push(<DecisionMake  {...screen} observation={observation} title={_get(translation, `${observation}.titleHelp`)} handPointer={true} />);
			break;

		case 'truk_module':
			const trukHelp = _get(screen, 'helpImgs', null);
			trukHelp.map((img, i) => {
				screens.push( (<ImageContainer key={i}>
					<p style={{ textAlign: 'center' }}>{_get(translation, `${observation}.helpText${i + 1}`, '')}</p>
					<img src={loadImage(img)} alt={observation} />
				</ImageContainer>))
			})
			
			break;
		case 'coin_game':
			const coinHelp = _get(screen, 'helpImgs', null);
					coinHelp.map((img, i) => {
						screens.push ((<ImageContainer key={i}>
							<p style={{ textAlign: 'center' }}>{_get(translation, `${observation}.help_text${i + 1}`, '')}</p>
							<img src={loadImage(img)} alt={observation} />
						</ImageContainer>))
					});
			break;
		case 'hover_slider':
			const hoverSliderHelp = _get(screen, 'helpImgs', null);
			screens.push(<div style={{ textAlign: 'center', margin: '20px auto' }}>
					<p style={{ textAlign: 'center' }}>{_get(translation, `${observation}.helpText`, '')}</p>
				<img  src={loadImage(hoverSliderHelp)} alt={hoverSliderHelp} />
			</div>);
			break;

		case 'group_image_button':
			const GroupHelpImg = _get(screen, 'helpImgs', null);

					GroupHelpImg.map((img, i) => {
						screens.push( (<ImageContainer key={i}>
									<p style={{ textAlign: 'center' }}>{_get(translation, `${observation}.titleHelp`, '')}</p>
							<img  src={loadImage(img)} alt={observation} />
						</ImageContainer>))
					})
			break;
		case 'fill_amount':
			const fake_props_fill_amt = {
				...screen,
				handPointer: true,
				defaultValue: 1,
				onChangeHandler: 'fake'
			};
			screens.push(<FillAmount key={0} {...fake_props_fill_amt} observation={observation} />);
			break;
			
		case "servey_rating":

			const options_rating = screen.options.slice(0, 5);
			const fake_data_rating = options_rating.map(option => {
				return option.value
			});
			fake_data_rating.slice(0, 2).forEach((fake, index) => {
				const fake_props_rate = {
					...screen,
					options: options_rating,
					defaultValue: fake,
					handPointer: true
				};

				screens.push(<GroupRadioRating key={index} {...fake_props_rate} observation={observation} />)
			});
			break;

		case "end_intro":
			const fake_props_ratess = {
				...screen,
				handPointer: true,
				defaultValue: 1,
				onChangeHandler: 'fake'
			};
			screens.push(<EndIntro key={0} {...fake_props_ratess} observation={observation} />);
			break;

		case 'confidentiality_check':
			const confidentiality_checkbox = screen.options.slice(0, 1);
			const confidentiality_checkbox_fake_data = confidentiality_checkbox.map(option => {
				return option.value
			});
			confidentiality_checkbox_fake_data.forEach((fake, index) => {
				let defaultValue = [];
				let hide_hands = false;
				let handTarget = null;

				if (index === 0) {
					defaultValue = [fake];
					handTarget = fake
				}

				const fake_props = {
					...screen,
					options: confidentiality_checkbox,
					defaultValue,
					handPointer: true,
					hide_hands,
					handTarget,
					value: {}
				};

				screens.push(<ConfidentialityCheck key={index} {...fake_props} observation={observation} />)
			});

			break;

	}
	return screens;
};

const HelperModal = (props) => {
	const translation = useContext(LocaleContext);
	const { isOpen, showHelper, screen = {}, exitGame, helpModalStateHandler, type, observation, help, ready_state, journey_state } = props;
	let { hideHalpNavigation = false } = screen;
	const recipes = recipeManager({ type, screen, observation, journey_state, translation });
	const [pointer, setPointer] = useState(0);
	const [is_last, setLastScreenStatus] = useState(false);
	const [itsExit, setExit] = useState(false);
	const [is_first, setFirstScreenStatus] = useState(true);
	const [modalState, setModalState] = useState(isOpen || false);
	const help_timers = useHelpModalTimerReducer(observation, modalState);

	useEffect(() => {
		setPointer(0);
		setLastScreenStatus(false);
		setFirstScreenStatus(true);
		setExit(false);
		if (typeof helpModalStateHandler === 'function') {
			helpModalStateHandler({ open: modalState, help_timers })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [observation, modalState]);

	const selectRecipe = (target) => {
		if (target > (recipes.length - 1) || target < 0) return;

		setLastScreenStatus(target === (recipes.length - 1));
		setFirstScreenStatus(target <= 0);
		setPointer(target)
	};

	const handleBack = () => {
		selectRecipe(pointer - 1)
	};

	const handleNext = () => {
		if (is_last) setModalState(false)
		selectRecipe(pointer + 1)
	};

	const toggle = () => setModalState(!modalState)

	const isVisible = (hel) => {
		const helpType = typeof hel;
		let is_visible = false;

		if (helpType === 'boolean') {
			is_visible = hel;
		} else if (helpType === 'object') {
			is_visible = RuleManager(hel.rules || [], { screen_name: observation, ready_state })
		}
		return is_visible
	};

	const instructions = instruction_translations(type, translation);

	return (
		<Fragment>
			<Trigger hidden={showHelper} onClick={() => setModalState(true)} visible={isVisible(help)}>{_get(translation, 'help_modal.title', 'Help')}</Trigger>
			<ModalContainer size="lg" centered={true} isOpen={modalState} onClosed={() => setModalState(false)}>
				<Header toggle={toggle}>{_get(translation, 'help_modal.title', 'Help')}</Header>
				<Instructions hidden={!instructions[pointer]}>{instructions[pointer]}</Instructions>
				<Body clickable={hideHalpNavigation.toString()}>
					{recipes[pointer]}
				</Body>
				<Footer hidden={hideHalpNavigation}>
					<PreviousButton onClick={handleBack} is_first={is_first}>{_get(translation, 'help_modal.previous', 'Previous')}</PreviousButton>
					<NextButton onClick={handleNext} is_last={is_last}>
						{is_last ? _get(translation, 'help_modal.done', 'Done') : _get(translation, 'help_modal.next', 'Next')}
					</NextButton>
				</Footer>
				<Buttons hidden={!showHelper || itsExit}>
					<PreviousButton onClick={() => setModalState(false)}>{_get(translation, 'help_modal.continue', 'Continue')}</PreviousButton>
					<NextButton onClick={() => exitGame(true)}>{_get(translation, 'help_modal.exit', 'Exit')}</NextButton>
				</Buttons>
			</ModalContainer>
		</Fragment>
	)
};

export default HelperModal;

import styled from 'styled-components';
import hand from '../../assets/images/hand.svg'

export const Input = styled.input`
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #D1D3D4;
  background-image: none !important;

  &:focus {
      outline: 0;
      box-shadow: none !important;
  }
`;

export const InputContainer = styled.div`
  :after {
    ${({pointer}) => pointer && `
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 200px;
      z-index: 1;
    `}
    
    ${({hide_hands}) => !hide_hands ? `background: url(${hand}) center center no-repeat;` : 'none;'}
  }
`;

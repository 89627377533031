import styled from "styled-components";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

export const CoinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  max-width: 28%;
  margin: 25px auto;
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 40%;
  }
  @media (min-width: 428px) and (max-width: 768px) {
    max-width: 70%;
  }
  @media (min-width: 320px) and (max-width: 427px) {
    max-width: 100%;
  }
`;
export const Coin = styled.div`
  width: 10%;
  padding: 6px 0px;
  text-align: center;
  img {
    background-color: ${({ value }) => (value ? "#FFD535" : "#F5F5F5")};
  }
`;

export const TextWrapper = styled.div`
  width: 55%;
  margin: 15px auto;
  @media (min-width: 320px) and (max-width: 427px) {
    width: 100%;
  }
`;

export const Field = styled.div`
  display: flex;
  padding: 10px;
`;
export const FieldWrapper = styled.div`
  width: 50%;
  margin: auto;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 65%;
  }

  @media (min-width: 428px) and (max-width: 767px) {
    width: 80%;
  }

  @media (min-width: 320px) and (max-width: 427px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-left: auto;
  .counter {
    display: flex;
    justify-content: center;
    border: 1px solid ${({ value }) => (!value ? "#eee" : "#feb61a")};
    border-radius: 50%;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0px 5px;
    background-color: ${({ value }) => (value ? "#FFD535" : "#F5F5F5")};
  }

  @media (min-width: 320px) and (max-width: 427px) {
    .counter {
      margin: 0px 2px;
    }
  }
`;
export const NextButton = styled.button`
align-items: center;
justify-content: center;
padding: 3px 14px;
border-radius: 30px;
background: #BD1E6F;
outline: none;
font-size: 19px;
color: white;
margin: 0 10px;
border: 0;
&:hover {
background: #eceff2;
`;

export const ActionButton = styled.button`
  cursor: ${({ value, disabled }) =>
    value !== 0 && !disabled ? "pointer" : ""};
  display: flex;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.primary || "#0B2972"};
  padding: 0;
  border-radius: 50%;
  border: none;
  width: 35px;
  margin: 0px 5px;
  height: 35px;
  text-align: center;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  color: #fff;
  @media (min-width: 320px) and (max-width: 427px) {
    margin: 0px 2px;
  }
  :active {
    background-color: #049ed7;
  }
`;
export const PopModalBody = styled(ModalBody)`
padding: 25px;
text-align: center;
  div {
    font-size: 20px;
  }
  p {
    margin: 0px 30px;
  }
  @media (min-width: 320px) and (max-width: 425px) {
    div {
      margin: 15px 15px;
      font-size: 20px;
    }
    p {
      margin: 0px 15px;
    }
  }
`;
export const PopUp = styled(Modal)`
  [class~="modal-content"] {
    margin-top: 20%;
    border-radius: 20px;
  }
  @media (min-width: 320px) and (max-width: 425px) {
    [class~="modal-content"] {
      width: auto;
      margin: 35% 13px;
    }
  }
`;

export const NextBtn = styled.div`
  text-align: right !important;
  margin-top: 20px;
`;
export const ContentSub = styled.div`
  margin-bottom: 20px;
`;
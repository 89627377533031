'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { SelectButton, SelectMenu, SelectContainer, SelectOptions } from './SelectStyles';

import util from 'util'

function useOutsideAlerter(ref, updateMenuState, help) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !help) {
        updateMenuState(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Select = ({ options, v2, label, help, responseHandler, defaultValue, value, pointerHelp, handPointer, hide_hands, isOpen, selectedVal }) => {
  const [selected, setSelected] = useState(value || defaultValue);
  const [sequence, setSelectSequence] = useState([]);
  const [showMenu, updateMenuState] = useState(isOpen);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, updateMenuState, help);

  useEffect(() => {
    setSelected(selectedVal);
  }, [selectedVal]);

  useEffect(() => {
    updateMenuState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (util.isFunction(responseHandler)) responseHandler({ value: selected, sequence });
  }, [selected]);

  const handleSelect = (value) => {
    if (typeof value === 'number') {
      setSelected(value);
      setSelectSequence(sequence => {
        sequence.push(value);
        return sequence
      });
    }
    updateMenuState(false);
  };
  
  return <div>
    <SelectContainer ref={wrapperRef} pointer={handPointer} hide_hands={hide_hands}>
      <SelectButton v2={v2} className={`btn dropdown-toggle my-2`} onClick={() => { updateMenuState(!showMenu) }}>
        {selected || label}
      </SelectButton>
      <SelectMenu help={help} v2={v2} visible={showMenu}>
        <SelectOptions pointer={pointerHelp} onClick={() => handleSelect(label)} selected={!selected}>{label}</SelectOptions>
        {
          options.map((option, i) => {
            return <SelectOptions key={i} selected={selected === option.value} onClick={() => handleSelect(option.value)}>{option.label}</SelectOptions>
          })
        }
      </SelectMenu>
    </SelectContainer>
  </div >
};

export default Select;
import React from 'react';

import {Checkbox, DummyLabel, Span, Option, ImageContainer, OptionWrapper, CheckboxWrapper, Label} from './CheckboxButtonStyles';
import {loadImage} from '../../helpers/image_loader'

const CheckBoxButton = ({ identifier, selected, pointer, hide_hands, label, image, onClick, type }) => {
  const genCheckBox = () => {
    let checkbox = <>
      <Checkbox type="checkbox" />
      <Span selected={selected}/>
      <Label htmlFor={identifier}>{label}</Label>
    </>;

    if (type === 'image') {
      checkbox = <CheckboxWrapper type={type}>
        {checkbox}
      </CheckboxWrapper>
    }

    return checkbox;
  };

  return <OptionWrapper type={type}>
    <Option key={identifier} id={identifier} pointer={pointer} hide_hands={hide_hands} onClick={onClick} type={type}>
      { genCheckBox() }
      {
        image && <ImageContainer>
          <img src={loadImage(image)} alt={image}/>
        </ImageContainer>
      }
      <DummyLabel />
    </Option>
  </OptionWrapper>
};

export default CheckBoxButton
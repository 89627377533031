'use strict';

import React, { useContext, useEffect, useState } from 'react'
import Slider from 'rc-slider';
import PropTypes from 'prop-types'
import numeral from 'numeral'
import _get from 'lodash.get'

import { SliderBaseContainer, SliderBaseLabel, BaseOption, SliderBase, SliderBaseBackground } from '../../components/BaseSlider/BaseSliderStyle'
import { GlobalBaseStyle, CenterContent, Question, Hint, Instruction } from '../../assets/styles/BaseStyle';

import BaseSlider from '../../components/BaseSlider/BaseSlider'
import SliderHandle from '../../components/BaseSlider/SliderHandle'
import { RcSliderStyle } from '../../assets/styles/RCSliderStyle';
import sliderBackground from '../../assets/images/slider-bg.svg'
import { extract_text } from '../../helpers';
import { LocaleContext } from '../../context/LocalContext';

import { loadImage } from '../../helpers/image_loader';
import { SliderImage } from './SimpleSliderStyles';

const SimpleSlider = ({
  options, title, sub_title, value, defaultValue, observation, min,
  max, marks, step, handPointer, prefix, suffix, separator, onChange,
  onAfterChange, center, image, instruction
}) => {
  const translation = useContext(LocaleContext);

  const applyFormatting = (value) => {
    const evaluatedSuffix = (typeof suffix === 'function') ? suffix(value) : suffix;
    const formattedValue = separator ? numeral(value).format(`0${separator}0`) : value;

    let formatting = '';

    if (prefix) formatting += `${prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;

    return formatting
  };

  const [sliderValue, setSliderValue] = useState(value || defaultValue);

  useEffect(() => {
    setSliderValue(value || defaultValue)
  }, [observation]);

  const handleSlide = (value) => {
    const offset = 100 / options.length;
    const idx = Math.ceil((value / offset) - 1);
    const opt_label = _get(options[idx], 'label') || applyFormatting(value);

    setSliderValue(value);

    if (typeof onChange === 'function') onChange(value, opt_label);
  };

  const props = {
    onAfterChange,
    onChange: handleSlide,
    max,
    min,
    step,
    marks,
    value: sliderValue,
    handle: SliderHandle,
    key: 'slider'
  };

  const slider_options = options.map((opt, i) => {
    const opt_label = _get(opt, 'label') || _get(translation, `${observation}.option${i + 1}`) || applyFormatting(i);
    return <BaseOption align={'left'} key={i}>{opt_label}</BaseOption>;
  });

  return <>
    <GlobalBaseStyle />
    <RcSliderStyle />
    <CenterContent center={center}>
      {
        !handPointer && <>
          <Question alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question`)}</Question>
          <Instruction
            visible={instruction}
            alignment={_get(instruction, 'alignment', 'left')}
          >
            {extract_text(instruction) || _get(translation, `${observation}.instruction`, '')}
          </Instruction>
          <Hint
            visible={sub_title} render_as={_get(sub_title, 'as', 'hint')}
            alignment={_get(sub_title, 'alignment', 'left')}
          >
            {extract_text(sub_title) || _get(translation, `${observation}.hint`, _get(translation, `${observation}.subquestion`))}
          </Hint>
        </>
      }
      <SliderImage>
        <img src={loadImage(image)} />
      </SliderImage>
      <SliderBaseContainer pointer={handPointer}>
        <SliderBaseLabel>
          {slider_options}
        </SliderBaseLabel>
        <SliderBase>
          <SliderBaseBackground src={sliderBackground} />
          <Slider {...props} />
        </SliderBase>
      </SliderBaseContainer>
    </CenterContent>
  </>
};

SimpleSlider.defaultProps = {
  title: '',
  sub_title: ''
};

export default BaseSlider(SimpleSlider);

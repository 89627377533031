import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { GlobalBaseStyle, CenterContent,Hint, Question } from '../../assets/styles/BaseStyle';
import { InstructionImage } from './InstructionsStyle';
import {extract_text} from '../../helpers';
import {LocaleContext} from '../../context/LocalContext';
import {loadImage} from '../../helpers/image_loader';
import _get from 'lodash.get';
import FireImage from "../../components/FireImage/index"
const InstructionScreen = ({ observation, image, instruction, hint }) => {
  const translation = useContext(LocaleContext);
  const imageType = typeof image;
  const _hint = extract_text(hint) ||
      _get(translation, `${observation}.hint`,
          _get(translation, `${observation}.instruction2`,
              _get(translation, `${observation}.subtitle`)));

  return (
    <Fragment>
      <GlobalBaseStyle />
      <CenterContent center={true}>
        <Question alignment={_get(instruction, 'alignment', 'center')}>
          {
            extract_text(instruction) ||
            _get(translation, `${observation}.title`,
                _get(translation, `${observation}.instruction`,
                    _get(translation, `${observation}.instruction1`)))
          }
        </Question>
        <Hint visible={_hint} alignment={_get(hint, 'alignment', 'center')} render_as={_get(hint, 'render_as', 'title')}>{_hint}</Hint>
        {typeof image === "string" && image.includes('fire_build_game/') ? <FireImage image ={image} text={_get(translation, `${observation}`)}/>:
        <InstructionImage width={imageType === 'object' ? image.width : 100}>
          <img src={loadImage(imageType === 'object' ? image.source : image)} alt=""/>
        </InstructionImage>}
      </CenterContent>
    </Fragment>
  )
};

InstructionScreen.defaultProps = {
  image: ""
};

InstructionScreen.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sub_title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  image: PropTypes.string
};

export default InstructionScreen

'use strict';

const setTheme = ({
    primary, secondary, alternate,
    font_color_1, font_color_2, font_color_3,
    navigation, help, action, progress_bar, timer
}) => {
    const _primary = primary || '#BD1E6F'
    const _secondary = secondary || '#66CC33'
    const _alternate = alternate || '#DDE2E8'

    const _font_color_1 = font_color_1 || '#FFFFFF';
    const _font_color_2 = font_color_2 || '#212121'; 
    const _font_color_3 = font_color_3 || '#67CA37';

    const _action = {
        background: _secondary,
        color: _font_color_1,
        ...(action && action)
    }

    const _navigation = {
        background: _primary,
        color: _font_color_1,
        ...(navigation && navigation)
    }

    const _help = {
        background: _alternate,
        color: _font_color_3,
        navigation: {
            background: _primary,
            alternate: _secondary,
            color: _font_color_1
        },
        ...(help && help)
    }

    const _timer = {
        navigation: {
            background: _primary,
            alternate: _secondary,
            color: _font_color_1
        },
        ...(timer && timer)
    }

    const button = {
        navigation : _navigation,
        action: _action,
        help: _help,
        timer: _timer  
    }

    const header = {
        progress_bar: _primary,
        ...(progress_bar && progress_bar)
    }

    const colors = {
        primary: _primary,
        secondary: _secondary,
        alternate: _alternate,
        font_color_1: _font_color_1,
        font_color_2: _font_color_2,
        font_color_3: _font_color_3
    }

    const radio = {
        background: _primary
    }

    const checkbox = {
        background: _primary
    }

    return {
        colors,
        header,
        button,
        radio,
        checkbox,
        timer
    }
}

export default setTheme

import styled from 'styled-components'

export const Text = styled.div`
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color:${({ status }) => status !== 'success' ? `#000` : `#fff`};
    @media (min-width: 320px) and (max-width: 426px){
       font-size:17px;
    }
`;

export const Boxs = styled.div`
display:flex;
justify-content:center;
align-items:center;
`;

export const Body = styled.div`
    margin:25px auto;
    background: ${({ status }) => status === 'success' ? `#ADD253` : `#EA2125`};
    width: 100px;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    color:${({ status }) => status === 'success' ? `#000` : `#fff`};;
    @media (min-width: 320px) and (max-width: 426px){
        width: 81px;
        margin: 9px auto 20px auto;
    }
`;
export const Box = styled.div`
    margin:auto;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:white;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-size: 30px;
    border:1px solid black;
    color:${({ status }) => status === 'success' ? `#000` : `#EA2125`};
    @media (min-width: 320px) and (max-width: 426px){
        height: 24px;
        font-size: 19px;
    }
`;


export const TrukImg = styled.div`
    display: flex;
    justify-content: center;
    margin: 50px;
    align-items: center;
    img{
        width:50%;
    }
   @media (min-width:425px) and (max-width:768px){
        img{
            width:75%;
        }
    }

    @media (min-width:320px) and (max-width:426px){
            margin: 35px 50px;
        img{
            width:100%;
            max-width: 100%;
        }
    }
    
`;
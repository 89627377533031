import React, { Fragment, useContext, useEffect, useState } from 'react';
import { GlobalBaseStyle, CenterContent } from '../../assets/styles/BaseStyle';
import { extract_text } from '../../helpers';
import _get from 'lodash.get';
import { LocaleContext } from '../../context/LocalContext';
import { ContentWrapper, LeftPane, RightPane, DummyModal,Option, TextStyle } from './EndIntroStyle';
import { loadImage } from '../../helpers/image_loader';

const EndIntro = ({ title, sub_title, image, observation, onChangeHandler, handPointer }) => {

    const translation = useContext(LocaleContext);
    const [timeVal, setFormate] = useState('0');
    const [startTime, setStartTime] = useState('00:00');
    
    const formatInputTime = (input_val) => {
        if (input_val === "" || input_val === "0") {
            setFormate('0')
            return 0;
        }
        input_val = parseInt(input_val, 10);
        setFormate(input_val);
    }

    useEffect(() => {
        let DateTime = localStorage.getItem('startDate');
        if(DateTime && DateTime != null) {
            setStartTime(DateTime);
        }
    }, [])

    useEffect(() => {
        if (typeof onChangeHandler === "function") {
            
            let int = +timeVal;
            onChangeHandler({ value: timeVal, itsDone: int !== 0 })
        }
    }, [timeVal])

    const manageValue = (value, status) => {
        let int = +value;
        if (status) {
            formatInputTime((int + 1).toString())
        } else {
            if (int !== 0) formatInputTime((int - 1).toString())
        }
    }

    return (
        <Fragment>
            <GlobalBaseStyle />
            <TextStyle />
            <CenterContent center={true}>
            <div style={{ justifyContent: 'center' }} className="row">
                {
                    !handPointer && <>
                    <LeftPane className={`col-xs-12 col-md-6`}>
                        <img src={loadImage(image)} />
                    </LeftPane>
                 
                    <RightPane className={`col-xs-6 col-md-6`}>
                        <div className={`lefl-module-sub-title moduleSubTitle`}>
                            <p> {extract_text(sub_title) || _get(translation, `${observation}.title`) + startTime}  </p>
                            <p> {extract_text(sub_title) || _get(translation, `${observation}.subText`)}</p>
                        </div>
                        <ContentWrapper>
                            <input className="int_input" type="text" value={timeVal} onChange={({ target }) => formatInputTime(target.value)} />
                            <button className="circle" onClick={({ target }) => manageValue(timeVal, false)}>-</button>
                            <button className="circle" onClick={({ target }) => manageValue(timeVal, true)}>+</button>
                            
                            <p className="labelMin">minutes</p>
                        </ContentWrapper>
                    </RightPane>
                    </> 
                }   
                {
                    handPointer && <>
                        <DummyModal>
                        <Option pointer={true}></Option>
                            <ContentWrapper>
                                <input className="int_input dummySt" type="text" readOnly value={timeVal} />
                                <button className="circle">-</button>
                                <button className="circle">+</button>
                            </ContentWrapper>
                        </DummyModal>
                    </>
                }
            </div >
            </CenterContent>
        </Fragment>
    );
};

export default EndIntro;
/* eslint-disable import/no-anonymous-default-export */
export default {
    screens: [
        {
            observation: 'dg_intro',
            type: 'intro',
            module: 'demo_graphic_info',
            meta: {
                v2: true,
                hiddenHeader: true,
                hideBottumNavigation: true,
                image: 'dg_intro',
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'birth_monthyear',
            type: 'combo_box',
            module: 'demo_graphic_info',
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                preset: {
                    type: 'date_picker',
                    meta: {
                        v2: true,
                        min: 1920,
                        max: new Date().getFullYear() - 18,
                        format: 'YYYY-MM'
                    },
                },
                value: {},
            },
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
        },
        {
            observation: 'gender2',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 3 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'education',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 7 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'marital_status',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 6 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'numberDependants',
            module: 'demo_graphic_info',
            type: 'numerical_slider',
            help: true,
            back: true,
            meta: {
                center: true,
                sub_title: { as: 'hint' },
                min: 0,
                max: 11,
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: ["observation"]
                }
            }
        },
        {
            observation: 'living_situation',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 4 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'time_residence',
            module: 'demo_graphic_info',
            type: 'numerical_slider',
            help: true,
            back: true,
            meta: {
                center: true,
                min: 0,
                max: 15,
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: ["observation"]
                }
            }
        },
        {
            observation: 'time_phone',
            module: 'demo_graphic_info',
            type: 'numerical_slider',
            help: true,
            back: true,
            meta: {
                center: true,
                min: 0,
                max: 15,
                other_option: {
                    meta: {
                        title: "",
                        options: [{ value: true }]
                    }
                }
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: ["observation"]
                }
            }
        },
        {
            observation: 'monthly_income',
            type: 'fill_amount',
            help: true,
            module: 'demo_graphic_info',
            back: true,
            meta: {
                helpImgs: ['monthly_incomeHelp1'],
                sub_title: { as: "hint" },
                symbl: '$',
                hideHalpNavigation: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['itsDone']
                }
            },
        },
        {
            observation: 'monthly_savings',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 2 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'monthly_savings2',
            type: 'fill_amount',
            help: true,
            module: 'demo_graphic_info',
            back: true,
            meta: {
                helpImgs: ['monthly_incomeHelp1'],
                sub_title: { as: "hint" },
                symbl: '$',
                hideHalpNavigation: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['itsDone']
                }
            },
        },
        {
            observation: 'source_income',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 8 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'time_source_income',
            module: 'demo_graphic_info',
            type: 'numerical_slider',
            help: true,
            back: true,
            meta: {
                center: true,
                min: 0,
                max: 15,
                dynamicLable: true,
                preObs: "source_income"
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: ["observation"]
                }
            }
        },
        {
            observation: 'work_hours',
            module: 'demo_graphic_info',
            type: 'numerical_slider',
            help: true,
            back: true,
            meta: {
                center: true,
                min: 5,
                max: 70,
                defaultValue: 38,
                other_option: {
                    meta: {
                        title: "",
                        options: [{ value: true }]
                    }
                }
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: ["observation"]
                }
            }
        },
        {
            observation: 'other_accounts',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 2 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'other_accounts2',
            module: 'demo_graphic_info',
            type: 'numerical_slider',
            help: true,
            back: true,
            meta: {
                center: true,
                min: 0,
                max: 15,
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            }
        },
        {
            observation: 'financial_product',
            type: 'checkbox_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: [
                    { value: 99 },
                    { value: 1 },
                    { value: 2 },
                    { value: 3 },
                    { value: 4 },
                    { value: 5 },
                    { value: 6 },
                    { value: 7 },
                ]
            }
        },

        {
            observation: 'number_loans',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 5 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'loan_amounts',
            type: 'fill_amount',
            help: true,
            module: 'demo_graphic_info',
            back: true,
            meta: {
                helpImgs: ['monthly_incomeHelp1'],
                sub_title: { as: "hint" },
                symbl: '$',
                hideHalpNavigation: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['itsDone']
                }
            },
        },
        {
            observation: 'interest_rate',
            type: 'radio_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: Array.from({ length: 4 }, (v, i) => ({ value: i + 1 }))
            }
        },
        {
            observation: 'monthly_payments',
            type: 'fill_amount',
            help: true,
            module: 'demo_graphic_info',
            back: true,
            meta: {
                helpImgs: ['monthly_incomeHelp1'],
                sub_title: { as: "hint" },
                symbl: '$',
                hideHalpNavigation: true
            },
            next: {
                rules: {
                    visible: true,
                    enabled: ['itsDone']
                }
            }
        },
        {
            observation: 'financial_product2',
            type: 'checkbox_group',
            module: 'demo_graphic_info',
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                }
            },
            back: true,
            meta: {
                title: {
                    alignment: 'left'
                },
                center: true,
                options: [
                    { value: 99 },
                    { value: 1 },
                    { value: 2 },
                    { value: 3 },
                    { value: 4 },
                    { value: 5 },
                    { value: 6 },
                    { value: 7 },
                ]
            }
        },
        {
            observation: 'greatJob',
            type: 'module_finish',
            module: 'demo_graphic_info',
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        monthly_savings: {
            values: ["2"],
            targets: ['monthly_savings2']
        },

        other_accounts: {
            values: ["2"],
            targets: ['other_accounts2', 'financial_product', 'number_loans']
        },

    },
}
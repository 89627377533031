import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import PropTypes from 'prop-types';

import { Container, Bar, Progress } from './ProgressBarStyles';

const ProgressBar = ({ progress, hidden }) => {
  return <>
    <Container hidden={hidden}>
      <Bar />
      <Progress progress={progress} />
    </Container>
  </>
};

ProgressBar.defaultProps = {
  progress: 0
};

ProgressBar.propTypes = {
  progress: PropTypes.number
};

export default ProgressBar;

import styled from "styled-components";
export const GameOptionButton = styled.div`
  display:flex;
  flex-direction:column;
  text-align: center;
  align-items:center;
  cursor:pointer;
  margin-bottom:50px;
  img {
    max-width: 130px;
    width:100%;
    height:80px;
    margin:20px auto;
    @media (max-width: 768px) {
      margin:2px auto;
      height:40px;
      width:70px;
    }
  }
  .button_otp {
    border-radius: 20px;
    width: 140px;
    padding:8px 10px;
    text-align: center;
    border: none;
    outline: none;
    background: #C4C8CC;
    @media (max-width: 768px) {
      width:100%;
      padding:4px 5px;
      font-size:12px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom:20px;
  }
  `;
export const GameImage = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  position: relative;  
img{
    max-width: 320px;
    width:100%;
    margin:0px auto; 
    @media (max-width: 768px) {
      width:100%;
    }
}
`;
export const ADDEDFIRE = styled.div`
position: absolute;
left: 50%;
top: 58%;
transform: translate(-50%,-50%);
  width:150px;
  height:50px;
  animation: pulse .7s linear;
  @keyframes pulse {
    0% {opacity:0;}
  100% {opacity:1;}
  }
  svg{
    postion:relative;
    width:100%;
    height:100%;
  }
    .text_added{
      font-size:12px;
    }
`;
export const Nav = styled.div`
    display: flex;
    padding-top: 15px;
	padding-bottom: 15px;
    justify-content: flex-end;
`;

export const NavButtons = styled.button`
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 1.8em;
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 25px;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
`;
export const ExitButtons = styled.button`
display:block;
  border-radius:  20px;
  background:${({ theme }) => theme.colors.primary};
  text-align: center;
  color:#fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  padding: 8px 25px;
  margin:0 auto;
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 768px) {
      width:100%;
    }
`;
export const ArrowRight = styled.span`
  :before {
    font-family: efl_iconfont;
    content: '\\52'
  }
`;

export const TextDiv = styled.div`
  .superMain {
    position: relative;
  }
  .subImdTxt {
    position: absolute;
    top: 52px;
    font-size: 9px;
    left: 30px;
    right: 30px;
    p{
      display:flex;
      .apTwo {
        margin-left: auto;
      }
      .apThree {
        margin-left: auto;
        margin-right:5px;
      }
    }
  }
 
  @media (max-width: 350px) {
    .subImdTxt {
      top: 48px;
      font-size: 8px;
    }
  }
`;
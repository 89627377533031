import _get from 'lodash.get';
import _shuffle from 'lodash.shuffle';
import {  useContext, useEffect, useState } from 'react';
import { GlobalBaseStyle } from '../../assets/styles/BaseStyle';
import { LocaleContext } from '../../context/LocalContext';
import { loadImage } from '../../helpers/image_loader';
import { ImgWraper, Img,Checkbox,Span } from './index';
import { ThemeProvider } from 'styled-components';
import setTheme from '../../context/ThemeProvider';


const RavenPattern = ({ options, observation, imgClicked,theme }) => {
    const translation = useContext(LocaleContext);
    
    const [answerOption,setAnswerOption] = useState([])
    const [userSelected,setUserSelected] = useState();
 
    useEffect(() => { 
        setAnswerOption(_shuffle(options));
        setUserSelected();
    },[observation]);
    
    return (
        <>
            <GlobalBaseStyle />
            <ThemeProvider theme={setTheme(theme || {})}>
                    <ImgWraper className={`imgGame`}>
                        {
                            answerOption.map(({ img, correct }, i) => {
                                const { label = '' } = _get(translation, `${observation}.optionValue[${i}]`, [])
                                return (<Img key={i} onClick={() => {imgClicked({ correct, label, value: i + 1 });setUserSelected(i)}}>
                                    <Checkbox key={i} type="radio" />
                                    <div className="d-flex justify-content-center align-items-center mt-2">
                                    <p>{String.fromCharCode(97 + i)}</p><Span selected={userSelected === i}><span /></Span></div>
                                    <img style={{ cursor: 'pointer' }} src={loadImage(img)} alt={img} />
                                    
                                </Img>)
                            })
                        }
                    </ImgWraper>
           </ThemeProvider>
        </>
    )
};


export default RavenPattern;

import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {LocaleContext} from '../../context/LocalContext';
import {extract_text} from '../../helpers';
import Input from './Input';

import {parse_translation} from '../../helpers';

import _get from 'lodash.get';

import {CenterContent, GlobalBaseStyle,Question} from '../../assets/styles/BaseStyle';
const TextInput = ({questions, onChangeHandler, value, observation, center, combine_observation, currency,title}) => {
  const translation = useContext(LocaleContext);
  const [observations, setObservations] = useState(value || {});

  useEffect(() => {
    setObservations(value || {})
  }, [observation]);

  const handleChange = (value, name, input_error) => {
    const newObservations = {
      [name || observation]: input_error ? null : { value }
    };

    const _observations = {
      ...observations,
      ...newObservations
    };

    setObservations(_observations);

    let is_value_match = Object.keys(_observations).filter(key => {
      return _observations[key]
    }).length;

    if (typeof onChangeHandler === 'function') {
      if (combine_observation) {
        const keys = Object.keys(_observations);
        let combined = combine_observation.pattern;

        keys.forEach(key => {
          combined = combined.replace(key, _get(_observations[key], 'value'))
        });

        _observations[combine_observation.observation] = {
          value: combined
        };

        is_value_match -= 1;
      }

      onChangeHandler(is_value_match === questions.length ? _observations : null);
    }
  };

  const generateInputs = () => {
    return questions.map((question, index) => {
      const input_props = {
        ...question
      };

      const parsed_translation = parse_translation({ translation, observation, state: { ...input_props.validation }});

      input_props.question = extract_text(question.question) ||
          _get(parsed_translation, `${observation}.question${index + 1}`, _get(parsed_translation, `${observation}.question`));

      input_props.hint = extract_text(question.hint) ||
          _get(parsed_translation, `${observation}.hint${index + 1}`, _get(parsed_translation, `${observation}.hint`));

      input_props.question_alignment = _get(question, 'question.alignment', 'left');
      const previous_value = _get(value, `${question.observation || observation}.value`);
      if (question.validation && question.validation.empty) {
        input_props.validation = {
          ...input_props.validation,
          empty: extract_text(question.validation.empty) || _get(parsed_translation, `${observation}.missing_info`)
        }
      }

      return <Input {...input_props} key_name={index} key={`${observation}_${index}`} onChangeHandler={handleChange} value={previous_value} currency={currency}/>
    })
  };

  return <>
    <GlobalBaseStyle />
    <CenterContent center={center}>
   { title && <Question alignment={title.alignment}><b>{extract_text(title) || _get(translation, `${observation}.title`)}</b></Question>}   
      {generateInputs()}
    </CenterContent>
  </>
};

TextInput.defaultProps = {
  questions: []
};

export default TextInput;

import React from 'react'

const LoadingScreen = () => {
  return (
    <>
      <div className='loading-gif'>
      </div>
    </>
  )
}

export default LoadingScreen;
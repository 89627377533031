"use strict";

import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'

import {GlobalBaseStyle, CenterContent, Question, Hint, Warning, Instruction} from '../../assets/styles/BaseStyle';
import {Group, Title, SubContent} from './ConfidentialityCheckStyles'
import CheckBoxButton from '../../components/CheckboxButton/CheckboxButton'
import {extract_text} from '../../helpers';
import {LocaleContext} from '../../context/LocalContext';
import _get from 'lodash.get';
const configData = require ('../../configValues.json');

const ConfidentialityCheck = ({
  observation, title, options, center, handPointer,
  value, defaultValue, onChangeHandler, hide_hands,
  handTarget, clearing_options, type, sub_title, warning,
  instruction }) => {

  const translation = useContext(LocaleContext);
  const [selections, updateSelections] = useState(_get(value, 'value', defaultValue) || []);
  const [is_upated, setUpdatedStatus] = useState(false);
  const [selectionsLabels, updateSelectionsLabels] = useState(_get(value, 'labels', []));
	const [sequence, setSequence] = useState(_get(value, 'sequence', []));

	useEffect(() => {
    updateSelections(_get(value, 'value', defaultValue) || []);
    setSequence(_get(value, 'sequence', []));
  }, [observation, value]);

  const handleSelect = (value) => {
    const in_clearing_option = (val) => clearing_options && clearing_options.values.includes(val);
    const clearing_option_rule = clearing_options && clearing_options.rule;

    let newSelections = selections;
    setUpdatedStatus(true);

    // Handles un-checking
    if (selections.includes(value)) {
      newSelections = selections.filter(selected => selected !== value);
    } else {
      // Handles checking
      newSelections = selections.filter(selected => !in_clearing_option(selected)).concat([value]);
    }

    const shouldClearSelections = in_clearing_option(value) && newSelections.includes(value);
    let _selections = newSelections;

    if (shouldClearSelections) {
      _selections = selections.filter(selected => clearing_option_rule === 'group' && in_clearing_option(selected)).concat([value]);
    }

    updateSelections(_selections);
    const labels = [];

    options.forEach((option, index) => {
      if(_selections.includes(option.value)) labels.push(index)
    });

    const newSelectionsLabels = labels.map((index) => _get(options[index], 'label', _get(translation, `${observation}.option${index + 1}`)));
    updateSelectionsLabels(newSelectionsLabels);

    const newSequence = sequence;

    if (value !== newSequence[newSequence.length - 1]) newSequence.push(value);

    setSequence(newSequence);

    if (typeof onChangeHandler === 'function') {
      onChangeHandler(_selections && _selections.length ? { value: _selections, labels: newSelectionsLabels, sequence: newSequence } : null);
    }
  };

  const generateOptions = () => {
    return options.map((item, index) => {
      const is_selected = selections.includes(item.value);

      return <CheckBoxButton
          key={index}
          identifier={index}
          onClick={() => handleSelect(item.value)}
          pointer={handPointer && handTarget === item.value}
          hide_hands={hide_hands}
          selected={is_selected}
          image={item.image}
          label={_get(item, 'label' , _get(translation, `${observation}.option${index + 1}`))}
          type={type}
        />
    });
  };

  return <>
    <GlobalBaseStyle />
    <CenterContent center={center}>
      <div>
        {
          !handPointer && <>
            <Title alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.title`)}</Title>
            <SubContent> {_get(translation, `${observation}.subtitle`)+` `+configData.clientName +_get(translation, `${observation}.subtitle_2`)}</SubContent>
            <SubContent><a href="https://www.begini.co/privacy/" target="_blank"> { _get(translation, `${observation}.linkText`) } </a></SubContent>
            <SubContent> { _get(translation, `${observation}.termText`) } </SubContent>
          </>
        }
      </div>
      <Group type={type}>
        {generateOptions()}
      </Group>
      {
        handPointer && <>
        <br/><br/><br/><br/><br/>
        </>
      }
    </CenterContent>
  </>
};

ConfidentialityCheck.defaultProps = {
	options: [],
  defaultValue: [],
	handPointer: false,
  title: '',
  sub_title: '',
  warning: '',
  instruction: ''
};

ConfidentialityCheck.propTypes = {
	value: PropTypes.object,
  handPointer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
	options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func
};

export default ConfidentialityCheck
import styled from 'styled-components';
import { RaisedButton } from '../../assets/styles/BaseStyle';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';

export const ModalContainer = styled(Modal)`
  [class~="modal-content"] {
		border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: .3rem;
	}
`;

export const Header = styled(ModalHeader)`
  border: 0;
  padding: 10px 15px;
`;

export const Instructions = styled.div`
  border: 0;
  padding: 1rem 1rem;
`;

export const Footer = styled(ModalFooter)`

${({ hidden }) => hidden && "display:none;"}

  border: 0;
  justify-content: space-between;
  margin-top: 30px;
  
  @media (max-width: 576px) {
   display: block
  }
  
  @media (max-width: 480px) {
    margin-top: 0;
  }
`;

export const Buttons = styled(ModalFooter)`
${({ hidden }) => hidden && "display:none;"}
  border: 0;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 480px) {
    margin-top: 0;
    padding: 13px 0px !important;
    padding-top: 0px !important;
  }
`;

export const Body = styled(ModalBody)`
  position: relative;
  padding:0px;
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    ${({ clickable }) => clickable === "true" ? "z-index: -1;" : "z-index: 2;"}
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
  }
`;

const Button = styled(RaisedButton)`
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  margin: 10px 0;
  display: block;
  border: 0;
  padding: 0.375rem 0.75rem;
  outline: 0;
  min-width: 120px;
  text-transform: uppercase;

  &:disabled {
    background: #DDE2E8;
  }
  
  @media (max-width: 576px) {
    margin: 10px auto 0px !important; 
  }
`;

export const NextButton = styled(Button)`
  background: ${({ is_last, theme }) => is_last ? theme.button.help.navigation.alternate : theme.button.help.navigation.background};
`;

export const PreviousButton = styled(Button)`
  visibility: ${({ is_first }) => is_first ? 'hidden' : 'visible'};
  background: ${({ theme }) => theme.button.help.navigation.background};
`;

export const Trigger = styled(RaisedButton)`
display: ${({ visible }) => visible ? 'flex' : 'none'};
align-items: center;
justify-content: center;
padding: 3px 14px;
border-radius: 30px;
background: #BD1E6F;
outline: none;
font-size: 19px;
color: white;
margin: 0 10px;
border: 0;
&:hover {
background: #eceff2;
}
`;
export const ImageContainer = styled.div`
  display:flex;
  text-align:center;
  align-items:center;
  flex-direction:column;
  max-height:340px;
  img{
    width:100%;
    max-width:300px;
    max-height:320px;
    height:100%;
    margin:0 auto;
    padding:10px 0;
  }
  .image-content{
    position:relative;
    .fire_txt{
      position: absolute;
    left: 8px;
    right: 0;
    font-size: 9px;
    top: 168px;
    }
  .subImdTxt {
    position: absolute;
   
    font-size: 9px;
    ${({ stage }) => stage === 0  && `
    top: 12px;
    left: 20px;
    right: 78px;`}
    ${({ stage }) => stage === 1  &&  
    `top: 12px;
    left: 22px;
    right: 20px;`}
    ${({ stage }) => stage === 2  &&`
    top: 12px;
    left: 22px;
    right: 20px;`}
    ${({ stage }) => stage === 3  &&`
    top: 15px;
    left: 8px;
    right: 6px;`}
    p{
      display:flex;
      margin-bottom:0;
      .apTwo {
        margin-left: auto;
      }
      .apThree {
        margin-left: auto;
        margin-right:5px;
      }
    }
  }
}
`;
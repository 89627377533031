// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'fb_intro',
            module: 'fire_build',
            type: 'intro',
            help: false,
            meta: {
                hiddenHeader: true,
                v2: true,
                image: "fire_build_game/fb_help1"
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            },
        },
        {
            observation: 'fb_exercise',
            module: 'fire_build',
            type: 'fire_building',
            help: true,
            auto_advance: true,
            timer: {
                time: 60000,
                extend: false,
                pause: true,
                visible: true
            },
            back: false,
            meta: {
                generate: {
                    delay: 30000,
                },
                hideHalpNavigation: false,
                helpImgs: ["fire_build_game/fb_help1", "fire_build_game/fb_help2", "fire_build_game/fb_help3", "fire_build_game/fb_help4"],
                image: "fire_build_game/fireFN0",
                option: ["fire_build_game/twig",
                    "fire_build_game/branch",
                    "fire_build_game/logs",
                    "fire_build_game/large_log"
                ]
            },
        },
        {
            observation: 'fb_burnt',
            module: 'fire_build',
            type: 'instructions',
            help: false,
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            },
            back: false,
            meta: {
               image: "fire_build_game/fireFN36",
            },
            next: {
                visible: true,
                enabled: true
            }
        },
        {
            observation: 'fb_redo',
            module: 'fire_build',
            type: 'radio_group',
            help: true,
            meta: {
                center: true,
                options: [
                    { value: 1 },
                    { value: 2 },
                    { value: 3 },
                ],
                sub_title: {}
            },
            back: false,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'fb_exercise_1',
            module: 'fire_build',
            type: 'fire_building',
            help: true,
            auto_advance: true,
            timer: {
                time: 60000,
                extend: false,
                pause: true,
                visible: true
            },
            back: false,
            meta: {
                generate: {
                    delay: 30000,
                },
                hideHalpNavigation: false,
                helpImgs: ["fire_build_game/fb_help1", "fire_build_game/fb_help2", "fire_build_game/fb_help3", "fire_build_game/fb_help4"],
                image: "fire_build_game/fireFN0",
                option: ["fire_build_game/twig",
                    "fire_build_game/branch",
                    "fire_build_game/logs",
                    "fire_build_game/large_log"
                ]
            },
        },
        {
            observation: 'fb_burnt_1',
            module: 'fire_build',
            type: 'instructions',
            help: false,
            auto_advance: true,
            timer: {
                time: 3000,
                extend: false,
                pause: false,
                visible: false
            },
            back: false,
            meta: {
               image: "fire_build_game/fireFN36",
            },
            next: {
                visible: true,
                enabled: true
            }
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "fire_build",
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],

    skip_keys: {
        fb_redo: {
            values: ["3"],
            targets: ['fb_exercise_1','fb_burnt_1']
        },
        fb_exercise: {
            values: ["done","auto"],
            targets: ['fb_redo','fb_exercise_1','fb_burnt','fb_burnt_1']
        },
        fb_exercise_1: {
            values: ["done","auto"],
            targets: ['fb_burnt_1']
        }
    }
}

import React, { useState } from 'react';
import Player from "../Player/Player";
import fin_des from '../Player/stories/fin_des';
import flash_card from '../Player/stories/flash_card';
import flash_card_v2 from '../Player/stories/flash_card_v2';
import financial_decisions_v2 from '../Player/stories/financial_decisions_v2';
import bus_info from '../Player/stories/business_info';
import finding_patterns from '../Player/stories/finding_patterns';
import fire_building from "../Player/stories/fire_building";
import con_decision from "../Player/stories/con_decision";
import coin_personal from "../Player/stories/coins_personal";
import coin_business from "../Player/stories/coins_business";
import selfimg from "../Player/stories/self_image";
import demographicinfo from "../Player/stories/demo_graphic_info";
import trans_start from "../Player/stories/trans_start";
import trans_end from "../Player/stories/trans_end";
import sand_truk from "../Player/stories/sand_truk";

const configData = require ('../../configValues.json');

const uncdf = [
  { props: fin_des, name: 'Financial Decision' },
  { props: flash_card, name: 'Flash Card' },
  { props: flash_card_v2, name: 'Flash Card v2' },
  { props: financial_decisions_v2, name: 'Financial Decisions v2' },
  { props: bus_info, name: 'Bus info' },
  { props: finding_patterns, name: 'Finding Patterns' },
  { props: fire_building, name: 'Fire Building' },
  { props: con_decision, name: "Consumption Decisions" },
  { props: sand_truk, name: 'Sand Truk' },
  { props: coin_personal, name: 'Coin Personal' },
  { props: coin_business, name: 'Coin Business' },
  { props: selfimg, name: 'Self Image' },
  { props: demographicinfo, name: 'Demo Graphic Info' },
  { props: trans_start, name: 'Welcome!' },
  { props: trans_end, name: 'End' }
];

const CommonContainer = ({ index }) => {
  const [modules] = useState(uncdf[index]);

  const onModuleFinish = (_observations, moduleName, status) => {
    const obj = { [moduleName]: _observations };
    console.log(obj);
  }

  return (
    <div className="App">

      <Player
        key={`${modules.name}`}
        module={{ locale: configData.language, ...modules.props }}
        onModuleFinish={onModuleFinish}
      />
    </div>
  );
}

export default CommonContainer;

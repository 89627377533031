import styled from 'styled-components';

import { RaisedButton } from '../../assets/styles/BaseStyle';

export const ThankYouButton = styled(RaisedButton)`
  font-family: efl_iconfont;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  font-size: 40px;
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.button.action.color};
  background-color: ${({ theme }) => theme.button.action.background};
  border: 0;
  
  :after {
    content: '\\54';
  }
`;

export const ThankYou = styled.div`
  margin: 20px 0;
`;

export const ThankYouContainer = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  @media (max-width:575px) and (min-width:320px){
    margin: 40% 0%;
  } 
`;
import React, { useState, useEffect, useRef } from 'react';
import _get from 'lodash.get';
import { useDigitSpan } from './games';
import { parse_translation } from '../helpers';

const RULE_MAP = {
  observation: ({ observations }) => {
    return observations !== null && observations !== undefined
  },
  override: ({ navigation_override, side, type }) => {
    return _get(navigation_override, `${side}.${type}`, false);
  },
  ready: ({ ready_state, screen_name }) => {
    return ready_state[screen_name]
  },
  itsDone: ({ observations }) => {
    return observations?.itsDone ? true : false
  }
};

export const RuleManager = (rules, meta) => {
  const rule_results = [];
  let total_pass = true;

  if (Array.isArray(rules) && rules.length > 0) {
    rules.forEach((rule) => {
      const sub_rule_results = [];

      if (Array.isArray(rule) && rules.length > 0) {
        rule.forEach(sub_rule => {
          sub_rule_results.push(RULE_MAP[sub_rule](meta))
        });

        rule_results.push(sub_rule_results.length === sub_rule_results.filter(Boolean).length)
      } else {
        rule_results.push(RULE_MAP[rule](meta))
      }
    });

    total_pass = rule_results.filter(Boolean).length >= 1
  }

  return total_pass;
};

export const useNavigationState = (screen, { observations, screen_name, sub_navigation, ready_state }) => {
  const meta = { observations, screen_name, sub_navigation, ready_state };

  const [nextButtonState, setNextButtonState] = useState({
    visible: screen.next && screen.next.visible,
    enabled: screen.next && screen.next.enabled
  });

  const [backButtonState, setBackButtonState] = useState({
    visible: screen.back && screen.back.visible,
    enabled: screen.back && screen.back.enabled
  });

  useEffect(() => {
    const getState = (side, type, meta) => {
      meta.side = side;
      meta.type = type;

      if (typeof screen[side] === 'boolean') return screen[side];

      if (typeof screen[side] === 'object') {
        const rules = screen[side] && screen[side].rules;

        if (!rules) return screen[side] && screen[side][type];

        return RuleManager(rules[type] || [], meta)
      }

      return false
    };

    setBackButtonState({
      visible: getState('back', 'visible', meta),
      enabled: getState('back', 'enabled', meta)
    });

    setNextButtonState({
      visible: getState('next', 'visible', meta),
      enabled: getState('next', 'enabled', meta)
    });
  }, [screen_name, observations, ready_state]);

  return {
    next: nextButtonState,
    back: backButtonState
  }
};

export const useScreenTimer = (screen_name) => {
  const [timers, updateTimers] = useState([]);
  const [lastScreen, setLastScreen] = useState(null);

  useEffect(() => {
    const existing_timer = timers.filter(timer => timer.screen === screen_name)[0];

    if (lastScreen !== screen_name) {
      const newTimers = timers.map(timer => {
        if (!timer.total) {
          const now = new Date().getTime();
          timer.end = now;
          timer.total = (now - timer.start) + (timer.previous_total || 0);
          delete timer.previous_total;
        }

        return timer
      });

      updateTimers(newTimers);
    }

    if (!existing_timer) {
      const newTimers = timers.concat([{ screen: screen_name, start: new Date().getTime() }]);
      updateTimers(newTimers);
    } else {
      const old_timers = timers.filter(timer => timer.screen !== screen_name);

      const newTimers = old_timers.concat([{
        screen: screen_name,
        start: new Date().getTime(),
        previous_total: existing_timer.total
      }]);

      updateTimers(newTimers);
    }

    setLastScreen(screen_name);
  }, [screen_name]);

  return timers
};

export const useHelpModalTimerReducer = (screen_name, state) => {
  const [timers, updateTimers] = useState([]);

  useEffect(() => {
    const existing_timer = timers.filter(timer => timer.screen === screen_name)[0];

    if (state) {
      if (!existing_timer) {
        const newTimers = timers.concat([{ screen: screen_name, start: new Date().getTime() }]);
        updateTimers(newTimers);
      } else {
        const old_timers = timers.filter(timer => timer.screen !== screen_name);

        const newTimers = old_timers.concat([{
          screen: screen_name,
          start: new Date().getTime(),
          previous_total: existing_timer.total
        }]);

        updateTimers(newTimers);
      }
    } else {
      const newTimers = timers.map(timer => {
        if (timer.screen === screen_name) {
          if (!timer.total) {
            const now = new Date().getTime();
            timer.end = now;
            timer.total = (now - timer.start) + (timer.previous_total || 0);
            delete timer.previous_total;
          }
        }

        return timer
      });

      updateTimers(newTimers);
    }

  }, [screen_name, state]);

  return timers
};

export const useScreenViewCounter = (screen_name) => {
  const [screens, updateScreens] = useState([]);

  useEffect(() => {
    const screen_tracked = screens.filter(screen => screen.screen === screen_name)[0];

    if (!screen_tracked) {
      const newScreens = screens.concat([{ screen: screen_name, views: 1 }]);
      updateScreens(newScreens);
    } else {
      updateScreens(screens => {
        screens.map(screen => {
          if (screen.screen === screen_name) {
            screen.views += 1
          }
        });

        return screens
      });
    }
  }, [screen_name]);

  return screens
};

export const useLanguageSequence = (screen_name, locale = 'en') => {
  const [locales, updateLocales] = useState({});

  useEffect(() => {
    const screen_tracked = locales[screen_name];

    if (!screen_tracked) {
      const newLanguages = locales;
      newLanguages[screen_name] = [locale];

      updateLocales(newLanguages);
    } else {
      const newLanguages = screen_tracked;
      newLanguages.push(locale);

      updateLocales({
        ...locales,
        ...newLanguages
      });
    }
  }, [screen_name]);

  return locales
};

export const useTimer = ({ timer, observation }) => {
  const [interval, setIntervalKey] = useState(null);
  const [remaining, setRemaining] = useState(timer && timer.time || Infinity);
  const [lastObservation, setLastObservation] = useState('');

  useEffect(() => {
    if (remaining <= 0) {
      clearInterval(interval);
      setRemaining(Infinity)
    } else if (remaining === Infinity) {
      setRemaining(timer && timer.time || Infinity)
    }
  }, [remaining, lastObservation]);

  useEffect(() => {
    if (lastObservation !== observation) {
      clearInterval(interval);
      setRemaining(Infinity);

      const interval_key = setInterval(() => setRemaining(remaining => remaining - 1000), 1000);
      setIntervalKey(interval_key);
    }

    setLastObservation(observation);

    return () => clearInterval(interval);
  }, [observation]);

  return remaining;
};

export const useTranslationManager = (screen_name, language = 'en', translations, { observation, state }) => {
  const [translation, setTranslation] = useState({});

  useEffect(() => {
    if (language && screen_name) {
      setTranslation(translations[language] && parse_translation({ translation: translations[language], observation, state }) || {})
    }
  }, [screen_name, language]);

  return translation;
};

export const games = {
  useDigitSpan
};

import { useContext, useEffect, useState } from 'react';
import { extract_text } from '../../helpers';
import _get from 'lodash.get';
import { GlobalBaseStyle } from '../../assets/styles/BaseStyle';
import { LocaleContext } from '../../context/LocalContext';
import { Body, Box, Boxs, Text, TrukImg, GoButton, Button, Question } from './TrukGameStyle';
import { loadImage } from '../../helpers/image_loader';
import { ThemeProvider } from 'styled-components';
import setTheme from '../../context/ThemeProvider';

const TrukGame = ({ theme, title, images = [], IncrementScore, InitScore, generate, observation, handleNavigation, onChangeHandler }) => {
    const translation = useContext(LocaleContext);
    const [totalPoints, setTpoints] = useState(InitScore);
    const [count, setCount] = useState(0);
    const [next, setNext] = useState(false);
    const [endScore, setScore] = useState(InitScore + ((images.length - 1) * IncrementScore));
    const [currentImg, setCurrentImg] = useState(images[0]);
    const [scoreBoard, SetScoreBoard] = useState([0, 0]);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        if (observation && typeof handleNavigation === "function") {
            const generateScreenTimeout = _get(generate, 'delay', 5000)+1000;
            setTimeout(() => {
                if(totalPoints === InitScore) onChangeHandler({ value: "expired", count, score: endScore, totalScore: totalPoints, InitScore, IncrementScore, observation });
            }, 2000);
            setTimeout(() => {
                setNext(true);
            }, generateScreenTimeout);
        }
    }, [observation])

    useEffect(() => {
        SetScoreBoard(() => {
            const board = totalPoints.toString().split('');
            if (board.length === 1) return [0, +board[0]];
            else return [...board];
        })
        onChangeHandler({ value: totalPoints > endScore ? 'danger' : 'success', count, score: endScore, totalScore: totalPoints, InitScore, IncrementScore, observation });
        if (totalPoints > endScore) {
            setNext(true);
        }
        setCount(count + 1);
    }, [totalPoints]);

    useEffect(() => {
        buttonState && setTimeout(() => setButtonState(false), 1000);
    }, [buttonState])

    useEffect(() => {
        if (next) {
            handleNavigation(1);
            setNext(false);
        }
    }, [next])

    const setPoint = (collectPoints) => {
        setTpoints(() => totalPoints + IncrementScore);
        if (collectPoints) {
            if (count === 1)
                onChangeHandler({ value: totalPoints > endScore ? 'danger' : 'success', count, score: endScore, totalScore: totalPoints, InitScore, IncrementScore, observation });
            setNext(true);
        }
        setCurrentImg(images[count]);
        setButtonState(true);
    }

    return (
        <>
            <GlobalBaseStyle />
            <ThemeProvider theme={setTheme(theme || {})}>
                <Question alignment={_get(title, "alignment", "center")}>
                    {extract_text(title) ||
                        _get(
                            translation,
                            `${observation}.title`
                        )}
                </Question>
                <Body>
                    <Text>Points</Text>
                    <Boxs>
                        <Box>{scoreBoard[0]}</Box>
                        <Box>{scoreBoard[1]}</Box>
                    </Boxs>
                </Body>
                <TrukImg>
                    <div className="mainImg">
                        <img src={loadImage(currentImg)} alt="" />
                    </div>
                </TrukImg>
                <GoButton>
                    <img onClick={() => buttonState || setPoint()} src={loadImage(buttonState ? 'SandTruck/st_goDisable' : 'SandTruck/st_goEnable')} alt="" />
                </GoButton>
                <div style={{ justifyContent: 'center' }} className="row m-0">
                    <Button onClick={() => setPoint(true)}>
                        <div>  {extract_text(title) ||
                        _get(
                            translation,
                            `${observation}.endGame`
                        )}</div>
                    </Button>
                </div>
            </ThemeProvider>
        </>
    );
};

export default TrukGame;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'fd_intro1',
            type: 'intro',
            module: 'financial_decisions',
            prograssBar: false,
            meta: {
                hiddenHeader: true,
                v2: true,
                image: 'fd_intro1'
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'fd_choice1',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "75",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice2',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "70",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice1",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice3',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "65",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice2",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice4',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "60",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice3",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice5',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "50",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice4",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_1month1',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "50",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice5",
                    delta: 3
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_2today1',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "50",
                value_2: "80",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: -1
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_intro2',
            type: 'intro',
            module: 'financial_decisions',
            prograssBar: false,
            meta: {
                hiddenHeader: true,
                v2: true,
                image: 'fd_intro1'
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'fd_choice6',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "150",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice7',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "140",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice6",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice8',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "130",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice7",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice9',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "120",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice8",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_choice10',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "110",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice9",
                    delta: 0
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_7months1',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "120",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "fd_choice10",
                    delta: 4
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            observation: 'fd_6months1',
            type: 'fin_choice',
            prograssBar: false,
            meta: {
                v2: true,
                value_1: "120",
                value_2: "160",
                prefix: "USD",
                generate: {
                    delay: 14999,
                    prev_ob: "",
                    delta: -2
                },
            },
            auto_advance: true,
            timer: {
                time: 15000,
                extend: false,
                pause: false,
                visible: true
            },
            module: 'financial_decisions'
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "financial_decisions",
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        fd_choice1: {
            values: ['80'],
            targets: ['fd_choice2', 'fd_choice3', 'fd_choice4', 'fd_choice5']
        },
        fd_choice2: {
            values: ['80'],
            targets: ['fd_choice3', 'fd_choice4', 'fd_choice5']
        },
        fd_choice3: {
            values: ['80'],
            targets: ['fd_choice4', 'fd_choice5']
        },
        fd_choice4: {
            values: ['80'],
            targets: ['fd_choice5']
        },
        fd_choice5: {
            values: ['50'],
            targets: ['fd_1month1', 'fd_2today1']
        },
        fd_1month1: {
            values: ['80'],
            targets: ['fd_2today1']
        },
        fd_choice6: {
            values: ['160'],
            targets: ['fd_choice7', 'fd_choice8', 'fd_choice9', 'fd_choice10']
        },
        fd_choice7: {
            values: ['160'],
            targets: ['fd_choice8', 'fd_choice9', 'fd_choice10']
        },
        fd_choice8: {
            values: ['160'],
            targets: ['fd_choice9', 'fd_choice10']
        },
        fd_choice9: {
            values: ['160'],
            targets: ['fd_choice10']
        },
        fd_choice10: {
            values: ['110'],
            targets: ['fd_7months1', 'fd_6months1']
        },
        fd_7months1: {
            values: ['160'],
            targets: ['fd_6months1']
        }
    }
}


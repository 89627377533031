
import React, { useState, useEffect } from 'react';
import Player from "../Player/Player";
import LoadingScreen from "../LoadingScreen";
import {
  flash_card_v2 ,
  financial_decisions_v2,
  finding_patterns,
  fire_building,
  coin_personal,
  selfimg,
  demographicinfo,
  trans_start,
  trans_end,
  sand_truk
} from './modules';

const configData = require ('../../configValues.json');

const moduleList = [

    { props: trans_start, name: 'Welcome!' },
    // { props: demographicinfo, name: 'Demo Graphic Info' },
    { props: fire_building, name: 'Fire Building' },
    { props: finding_patterns, name: 'Raven' },
    { props: financial_decisions_v2, name: 'Financial Decisions v2' },
    { props: coin_personal, name: 'Coin Personal' },
    { props: flash_card_v2, name: 'Flash Card v2' },
    { props: sand_truk, name: 'Sand Truk' },
    { props: selfimg, name: 'Self Image' },
    { props: trans_end, name: 'End' }
];

const Uncdf = () => {
  const [moduleStatus, completed] = useState(false);
  const [activeIndex, setIndex] = useState(0);
  const [final, setFinal] = useState({});
  const [modules, setModules] = useState(moduleList[activeIndex]);

  useEffect(() => {
    if (moduleStatus) {
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleStatus]);

  useEffect(() => {
    setModules(moduleList[activeIndex])
  }, [activeIndex]);

  const onModuleFinish = (_observations, moduleName, status) => {
    const obj = { [moduleName]: _observations };
    const y = { ...final, ...obj }
    if(moduleName === "finding_patterns" && activeIndex === 3 &&
     _observations.rt_gamepref.responseValue === 1){
      let a  = moduleList[activeIndex];
      a.props.screens.splice(0,1);
      moduleList.splice(9,0,a);
    }
    // if(moduleName === "demo_graphic_info" && _observations.time_phone.responseValue[0]){
    //   let a  = moduleList[8];
    //   a.props.screens.splice(16,1);
    //   moduleList[8] = a;
    // }
    setFinal(y);
    if (activeIndex !== (moduleList.length - 1)) {
      setIndex(activeIndex + 1)
    } else {
      completed(true)
    }
  }

  const sendData = () => {
    let f = { "uncdf": final };
console.log(f);

  }
  

  return (
    <div className="App">

      <Player
        key={`${modules.name}`}
        module={{ locale: configData.language, ...modules.props }}
        onModuleFinish={onModuleFinish}
      />
      {moduleStatus && <LoadingScreen />}
    </div>
  );
}

export default Uncdf;

"use strict";

import React, { Fragment, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import KeyboardEventHandler from "react-keyboard-event-handler";

import { GlobalBaseStyle, CenterContent } from "../../assets/styles/BaseStyle";
import {
  GameInstructionsImage,
  RightImage,
  LeftImage,
  Button,
  Mobile,
  NotMobile,
  CenteredText,
  Flip2,
  ButtonLabel
} from "./GamePageStyle";
import { Hint, Question } from "../../assets/styles/BaseStyle";
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";
import { loadImage } from "../../helpers/image_loader";
import _get from "lodash.get";
let getCurrentTime;
let myTimeOut;
let letestTime = 0;
const GamePageScreen = ({
  observation,
  instruction,
  error,
  title,
  button_titile1,
  button_titile2,
  keybord,
  image,
  images,
  v2,
  rotationtime,
  randomNumbers,
  time,
  onChangeHandler,
  crossIcon,
  practiceCount,
  handleNavigation,
  observationLabel,
}) => {
  const [timeOut, setTimeOut] = useState(0);
  const [count, setCount] = useState(0);
  const [lastItem, setLastItem] = useState(0);
  const [holdTime, setHoldTime] = useState(7);
  const [score, setScore] = useState(0);
  const [flip, setFlip] = useState(false);
  const [selected, setSelected] = useState(false);
  const [answer, setAnswer] = useState(true);
  const [blocker, setBlocker] = useState(false);
  const [btnView, setBtnView] = useState(true);
  const [obj, setObj] = useState({});
  const translation = useContext(LocaleContext);
  const imageType = typeof image;
  // const { initialMinute = 0, initialSeconds = time } = {};
  // const [minutes, setMinutes] = useState(initialMinute);
  // const [seconds, setSeconds] = useState(initialSeconds);
  // useEffect(() => {
  //   let myInterval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }
  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(myInterval);
  //       } else {
  //         setMinutes(minutes - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 500);
  //   return () => {
  //     clearInterval(myInterval);
  //   };
  // }, [observation]);

  const handleButtonClick = (e) => {
    check(e);
  };

  const submitScore = (value) => {
    onChangeHandler({ value: value, label: observation });
    // if (count == randomNumbers.length - 1) setSliderValue(value);
  };

  const imgFlipEffect = () => {
    setTimeout(function () {
      setAnswer(true);
    }, 500);
    setTimeout(function () {
      setBtnView(true);
    }, 1000);
    setTimeout(
      function () {
        setFlip(true);
        setCount(count + 1);
        setTimeout(
          function () {
            setFlip(false);
          },
          rotationtime ? 800 : 500
        );
        setBlocker(false);
      },
      rotationtime ? rotationtime : 600
    );
  };

  const check = async (sanswer) => {
    let obj = {};
    if (!blocker) {
      (timeOut === 0 && setTimeOut(1)) || setTimeOut(0);
      clearTimeout(myTimeOut);
      setBlocker(true);
      setSelected(sanswer);
      if (count > randomNumbers.length - 1) {
        setCount(randomNumbers.length - 1);
      } else {
        obj = {
          objID: count,
          imageIndex: randomNumbers[count],
          image: images[randomNumbers[count]],
          userAnswer: (sanswer && "l") || "a",
          correctAnswer: "",
          answerTime: Date.now() - getCurrentTime,
        };
        if ((lastItem == randomNumbers[count]) == sanswer) {
          setObj({ ...obj, correctAnswer: true });
          setScore(score + 1);
        } else {
          setObj({ ...obj, correctAnswer: false });
          if (count < practiceCount - 1) {
            setAnswer(false);
            setBtnView(false);
          }
        }
        imgFlipEffect();
        setLastItem(randomNumbers[count]);
        getCurrentTime = Date.now();
        if (!v2) submitScore(score);
      }
    }
  };

  useEffect(() => {
    if (v2) {
      if (5 > count) {
        setHoldTime(9);
      } else if (10 > count) {
        setHoldTime(7);
      } else if (15 > count) {
        setHoldTime(5);
      } else {
        setHoldTime(3);
      }

      if (timeOut > holdTime && v2) {
        imgFlipEffect();
        submitScore({
          objID: count,
          imageIndex: randomNumbers[count],
          image: images[randomNumbers[count]],
          userAnswer: null,
          correctAnswer: null,
          answerTime: timeOut,
          // userInput: false
        });
        setTimeOut(0);
      } else {
        myTimeOut = setTimeout(() => {
          setTimeOut(timeOut + 1);
        }, 1000);
      }
    }
  }, [timeOut]);

  useEffect(() => {
    setTimeOut(timeOut + 1);
    getCurrentTime = Date.now();
  }, []);

  useEffect(() => {
    if (v2) {
      setObj({
        objID: count,
        imageIndex: randomNumbers[count],
        image: images[randomNumbers[count]],
        userAnswer: null,
        correctAnswer: null,
        answerTime: timeOut,
        // userInput: false
      });
      submitScore(obj);
    }
    if (count > randomNumbers.length - 1)
      setTimeout(() => handleNavigation(1), 1);
  }, [count]);

  const ComponentA = (props) => {
    return (
      <div>
        <div>{props.eventKey}</div>
        <KeyboardEventHandler
          handleKeys={["a", "l"]}
          onKeyEvent={(key, e) => {
            if (count < randomNumbers.length) check(key == "l");
          }}
        />
      </div>
    );
  };
  return (
    <div>
      <Fragment>
        <ComponentA></ComponentA>
        <GlobalBaseStyle />
        {/* <CenterContent center={true}> */}
        <CenteredText>
          <div className={`game-title`}>
            {" "}
            {extract_text(title) ||
              _get(translation, `${observation}.title`)}{" "}
          </div>{" "}
        </CenteredText>
        <Flip2 v2={v2}>
          <div className={flip ? "flip-container active" : "flip-container "}>
            {" "}
            {/* hidden={observation && "s4_dsf_example" || false} */}
            <div hidden={v2 && true} className="flip-wrp">
              <div className={answer ? "fixed-img " : "fixed-img active"}>
                <div className="frontf"></div>
                <div className="backf">
                  <img
                    id="slidt"
                    src={loadImage(
                      count == 0
                        ? "game"
                        : images[
                            randomNumbers[
                              count < randomNumbers.length ? count - 1 : 0
                            ]
                          ]
                    )}
                  />
                </div>
              </div>
              <div className="flipper">
                <div className="front">
                  <img
                    id="slidt"
                    src={loadImage(
                      images[
                        randomNumbers[
                          count < randomNumbers.length ? count - 1 : 0
                        ]
                      ]
                    )}
                  />
                </div>
                <div className="back"></div>
              </div>
            </div>
            <div className={flip ? "main-img show" : "main-img "}>
              {" "}
              <img
                id="slide"
                src={loadImage(
                  images[
                    randomNumbers[count < randomNumbers.length ? count : 0]
                  ]
                )}
              />
            </div>
          </div>
          {/* <div className={flip ? "flip-container active" : "flip-container"}>
            <div  hidden={count<1} className="fixed-img"></div>
            <div className="flipper">
              <div className="front">
                <img
                  id="slide"
                  hidden={flip}
                  src={loadImage(images[randomNumbers[count < 20 ? count : 0]])}
                />
              </div>
              <div className="back"></div>
              <img
                hidden={answer}
                className="back"
                src={loadImage(
                  images[randomNumbers[count < 20 ? count - 2 : 0]]
                )}
              />
            </div>
          </div> */}
          <row>
            <GameInstructionsImage>
              <div hidden={btnView}>
                <img className="error-message" src={loadImage(crossIcon)} />
                <span className="error-span">
                  {extract_text(error) ||
                    _get(translation, `${observation}.error`)}{" "}
                  {selected ? "NO!" : "Yes!"}
                </span>
              </div>
              <NotMobile hidden={!btnView}>
                {extract_text(button_titile2) ||
                  _get(translation, `${observation}.button_titile2`)}
                <ButtonLabel>A</ButtonLabel>
                <img src={loadImage(keybord)} /><ButtonLabel>L</ButtonLabel>
                {extract_text(button_titile1) ||
                  _get(translation, `${observation}.button_titile1`)}
              </NotMobile>
              <Mobile hidden={!btnView}>
                <Button
                  className={`mr-4`}
                  onClick={() => handleButtonClick(false)}
                >
                  {extract_text(button_titile2) ||
                    _get(translation, `${observation}.button_titile2`)}
                </Button>
                <Button className={``} onClick={() => handleButtonClick(true)}>
                  {extract_text(button_titile1) ||
                    _get(translation, `${observation}.button_titile1`)}
                </Button>
              </Mobile>
            </GameInstructionsImage>
          </row>
        </Flip2>
        {/* </CenterContent> */}
        {/* </div> */}
      </Fragment>
    </div>
  );
};

GamePageScreen.defaultProps = {
  image: "",
};

GamePageScreen.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  onChange: PropTypes.func,
};

export default GamePageScreen;

import styled from 'styled-components';

export const Group = styled.div`
  text-align: left;
  label {
    font-size: 20px;
  }
  @media (max-width:767px){
    label {
      font-size: 16px;
    }
  }
  ${({type}) => type === 'image' && `
    display: flex;
    flex-wrap: wrap;
  `}
`;

export const Title = styled.div`
  text-align: left;
  font-size: 24px;
  margin: 20px auto;

`;
export const SubContent = styled.div`
  text-align: left;
  font-size: 20px;
  margin: 20px auto;
  a {
    color: rgba(0,0,0,.87);
    text-decoration: underline;
  }
  @media (max-width:767px){
    font-size: 16px;
  }
`;

import _get from 'lodash.get';
import { Fragment, useContext, useEffect, useState } from 'react';
import { GlobalBaseStyle, Question } from '../../assets/styles/BaseStyle';
import { ThemeProvider } from 'styled-components';
import { LocaleContext } from '../../context/LocalContext';
import { extract_text } from '../../helpers';
import { loadImage } from '../../helpers/image_loader';
import { QuestionImage, ImgWraper, Img, Radio } from './FindPatternStyle';
import RadioButton from '../../components/RadioButton/RadioButton'
import HelperModal from '../../components/HelperModal/HelperModal';
import setTheme from '../../context/ThemeProvider';
import RavenPattern from "../../components/RavenPattern/RavenPattern"
const FindPattern = ({ valueVisible,v2, theme, help, questionImage, title, jump, hideHalpNavigation, helpImgs, options, observation, jumpIndex, handPointer, onChangeHandler, optionValue, handleNavigation,value, ...props }) => {
    const translation = useContext(LocaleContext);
    const [showHelper, setHelper] = useState(false);
    const [isSelect, setSelect] = useState(false);
    const [exitGame, setExitGame] = useState(false);
    const [imgClick, setImgClick] = useState(false);

    const meta = { helpImgs, title, options, hideHalpNavigation };

    const imgClicked = (answer) => {
          onChangeHandler({ value: answer, label: observation });
          setImgClick(true);
    }
    useEffect(()=>{
   if(observation && observation === "rt_level03"){
       if(props.previousValues.rt_level01 === undefined && props.previousValues.rt_level02 === undefined){
        handleNavigation(5)
       }
   }
    },[observation])
    useEffect(() => {
        imgClick && handleNavigation(1);
        setImgClick(false);
    }, [imgClick])


    const setHelperModalState = ({ open }) => {
        setHelper(open);
        setSelect(open);
    }
    useEffect(() => {
        if (exitGame) {
            imgClicked({ skpGame: true });
            handleNavigation(jump);
        }
    }, [exitGame,imgClicked,handleNavigation,jump])
    return (
        <Fragment>
            <GlobalBaseStyle />
            <ThemeProvider theme={setTheme(theme || {})}>
                <QuestionImage v2={v2} handPointer={handPointer} >
                    <img className="QuestionImage" src={loadImage(questionImage)} alt={questionImage} />
                </QuestionImage>
                <div hidden={handPointer}>
                    <div style={{ marginBottom: '25px' }}>
                        <Question alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.title`)}</Question>
                    </div>
                   {v2 ? <ImgWraper v2={v2} className={`${!questionImage ? 'my-5' : ""} imgGame`}>
                        {
                            options.map(({ img, correct }, i) => {
                                const { label = '' } = optionValue[i]||_get(translation, `${observation}.optionValue[${i}]`, [])
                                return (<Img v2={v2} key={i} style={{}}>
                                    {!v2 && <p>{String.fromCharCode(65 + i)}</p>}
                                    <img onClick={() => imgClicked({ correct, label, value: i + 1 })} style={{ cursor: 'pointer' }} src={loadImage(img)} alt={img} />
                                    {v2 &&valueVisible==undefined && <p className="labelFont">{label}</p>}
                                </Img>)
                            })
                        }
                    </ImgWraper>:<RavenPattern options={options} observation={observation} imgClicked={imgClicked}/>}
                </div>
                <Radio hidden={handPointer}>
                    {
                        showHelper && <HelperModal
                            ready_state={{}}
                            help={help}
                            screen={meta}
                            type={'find_pattern'}
                            observation={observation}
                            journey_state={{}}
                            showHelper
                            isOpen={true}
                            exitGame={(e) => setExitGame(e)}
                            helpModalStateHandler={(state) => setHelperModalState(state)}
                        />
                    }
                    {_get(translation, `${observation}.option`, false) && <RadioButton
                        onClick={() => setHelper(true)}
                        pointer={handPointer}
                        selected={isSelect}
                        label={_get(translation, `${observation}.option`)}
                    />}
                </Radio>
            </ThemeProvider>
        </Fragment>
    )
};


export default FindPattern;

import React, { useContext, useState, useEffect } from "react";

import { GlobalBaseStyle, CenterContent, Question } from "../../assets/styles/BaseStyle";
import { ImageContainer, ImgWarrper } from "./DecisionMakeStyle";
import { extract_text } from "../../helpers";

import { LocaleContext } from "../../context/LocalContext";

import { loadImage } from "../../helpers/image_loader";
import _get from "lodash.get";

const DecisionMake = ({ valueVisible, optionValue = [],
  observation, title, option, handleNavigation, onChangeHandler
}) => {
  const translation = useContext(LocaleContext);
  const [opValue, setOpValue] = useState(_get(translation, `${observation}.optionValue`, optionValue));
  const [exitGame, setExit] = useState();
  useEffect(() => setExit(false), [observation])

  useEffect(() => setOpValue(_get(translation, `${observation}.optionValue`, optionValue)), [observation])

  useEffect(() => {
    if (exitGame && typeof handleNavigation === "function") {
      handleNavigation(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitGame]);
  const imageList = option || [];
  const donePlaying = (selected, text) => {

    if (typeof onChangeHandler === "function") {
      onChangeHandler({
        value: selected,
        label: opValue ? opValue[selected - 1] : text
      });
      setExit(true)
    }
  }

  return (<>
    <GlobalBaseStyle />
    <CenterContent center={true}>
      <div className="row">
        <div className="col-12">
          <Question alignment={_get(title, "alignment", "left")}>
            {extract_text(title) || _get(translation, `${observation}.title`)}
          </Question>
        </div>
      </div>
      <ImgWarrper count={imageList.length <= 2} className="w-100 row text-center">
        {imageList.map((field, i) => <div className="col-6 p-1" key={i + 3}>
          <ImageContainer src={loadImage(field)} alt={field} onClick={() => donePlaying(i + 1, field)} />
          {valueVisible && <p>{opValue[i]}</p>}
        </div>)}
      </ImgWarrper>
    </CenterContent>
  </>)
};
export default DecisionMake;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'rt_gamepref',
            module: 'finding_patterns',
            type: 'radio_group',
            meta: {
                center: true,
                defaultValue: null,
                title: {
                    alignment: 'left'
                },
                sub_title: {
                    alignment: 'left'
                },
                options: [
                    { value: 1 },
                    { value: 2 }
                ],
            },
            help: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'rt_intro',
            module: 'finding_patterns',
            type: 'intro',
            meta: {
                hideHalpNavigation: true,
                hideBottumNavigation: true,
                v2: true,
                image: "fc_intro"
            },
            auto_advance: true,
            timer: {
                time: 10000,
                extend: false,
                pause: false,
                visible: false
            }
        },
        {
            observation: 'rt_preguess',
            module: 'finding_patterns',
            type: 'simple_slider',
            help: true,
            meta: {
                min: 1,
                max: 100,
                marks: {},
                defaultValue: 50,
                options: [{  }, {  }, {  }],
                center: true,
                sub_title: { as: "title" },
            },
            back: false,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            observation: 'rt_level01',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                hideHalpNavigation: false,
                hideBottumNavigation: true,
                helpImgs: ["apple_help_1", "apple_help_2", "apple_help_3", "apple_help_4"],
                title: { alignment: 'center' },
                jump: 8,
                questionImage: 'pattern_apple',
                options: [{ img: 'apple_orange_top', correct: false }, { img: 'apple_orange', correct: false }, { img: 'apple_apple', correct: true }, { img: 'apple_apple_bottom', correct: false }]
            },
        },
        {
            observation: 'rt_level02',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                hideHalpNavigation: false,
                helpImgs: ["apple_help_1", "apple_help_2", "apple_help_3", "apple_help_4"],
                hideBottumNavigation: true,
                title: { alignment: 'center' },
                jump: 7,
                questionImage: 'pattern_orange',
                options: [{ img: 'orange_green', correct: true }, { img: 'orange_apple', correct: false }, { img: 'orange_orange', correct: false }, { img: 'orange_orange_apple', correct: false }]
            },
        },
        {
            observation: 'rt_level03',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                hideHalpNavigation: false,
                helpImgs: ["apple_help_1", "apple_help_2", "apple_help_3", "apple_help_4"],
                title: { alignment: 'center' },
                hideBottumNavigation: true,
                jump: 6,
                questionImage: 'pattern_two',
                options: [{ img: 'two_two', correct: false }, { img: 'two_bottom', correct: false }, { img: 'two_center', correct: true }, { img: 'two_three', correct: false }]
            },
        },
        {
            observation: 'rt_level04',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                hideHalpNavigation: false,
                jump: 5,
                helpImgs: ["apple_help_1", "apple_help_2", "apple_help_3", "apple_help_4"],
                hideBottumNavigation: true,
                title: { alignment: 'center' },
                questionImage: 'pattern_star',
                options: [{ img: 'star_appnge', correct: false }, { img: 'star_alt', correct: false }, { img: 'star_apple', correct: true }, { img: 'star_orange', correct: false }]
            },
        },
        {
            observation: 'rt_level05',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                hideHalpNavigation: false,
                jump: 4,
                hideBottumNavigation: true,
                helpImgs: ["apple_help_1", "apple_help_2", "apple_help_3", "apple_help_4"],
                title: { alignment: 'center' },
                questionImage: 'pattern_O',
                options: [{ img: 'O_apple', correct: true }, { img: 'O_left', correct: false }, { img: 'O_orange', correct: false }, { img: 'O_right', correct: false }]
            },
        },
        {
            observation: 'rt_level06',
            module: 'finding_patterns',
            type: 'find_pattern',
            help: true,
            auto_advance: true,
            timer: {
                time: 45000,
                extend: true,
                pause: true,
                visible: true
            },
            meta: {
                hideHalpNavigation: false,
                jump: 3,
                helpImgs: ["apple_help_1", "apple_help_2", "apple_help_3", "apple_help_4"],
                title: { alignment: 'center' },
                hideBottumNavigation: true,
                questionImage: 'pattern_sequence',
                options: [{ img: 'sequence_t_left', correct: false }, { img: 'sequence_apple', correct: false }, { img: 'sequence_right_t', correct: false }, { img: 'sequence_t_right', correct: true }]
            },
        },
        {
            observation: 'rt_postguess',
            module: 'finding_patterns',
            type: 'numerical_slider',
            help: true,
            meta: {
                center: true,
                min: 0,
                max: 6,
                sub_title: { as: 'title' },
            },
            next: {
                visible: true,
                enabled: false,
                rules: {
                    visible: true,
                    enabled: [
                        "observation"
                    ]
                }
            }
        },
        {
            observation: 'rt_postguess2',
            module: 'finding_patterns',
            type: 'simple_slider',
            help: true,
            meta: {
                min: 1,
                max: 100,
                marks: {},
                defaultValue: 50,
                options: [
                    {  },
                    {  },
                    {  }
                ],
                center: true
            },
            back: true,
            next: {
                rules: {
                    visible: true,
                    enabled: ['observation']
                },
                visible: true,
                enabled: false
            },
        },
        {
            type: "module_finish",
            observation: "greatJob",
            module: "finding_patterns",
            auto_advance: true,
            timer: {
              time: 3000,
              extend: false,
              pause: false,
              visible: false,
            },
            next: {
                visible: true,
                enabled: true
            }
        }
    ],
    skip_keys: {
        rt_gamepref: {
            values: ['1'],
            targets: ['rt_preguess', 'rt_intro', 'rt_level01', 'rt_level02', 'rt_level03', 'rt_level04', 'rt_level05', 'rt_level06', 'rt_postguess', 'rt_postguess2']
        }
    }
};
import React, { useContext,Fragment } from 'react';
import { LocaleContext } from '../../context/LocalContext';
import _get from 'lodash.get';
import { PopUp, TimerFooter, PopModalBody, CancelButton } from './TimeExpireModelStyle';

const TimeExpireModel = ({ observation, handleNavigation }) => {

    const translation = useContext(LocaleContext);

    const onClick = () => {
        handleNavigation(1);
    }

    return (
        <Fragment>
            <PopUp isOpen={true}>
                <PopModalBody>
                    <div>{_get(translation, `${observation}.title`)}</div>
                    <p>{_get(translation, `${observation}.intruction`)}</p>
                </PopModalBody>
                <TimerFooter>
                    <CancelButton onClick={onClick}>OK</CancelButton>{' '}
                </TimerFooter>
            </PopUp>
        </Fragment>
    );
};

export default TimeExpireModel;
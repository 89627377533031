import styled from 'styled-components';
import { RaisedButton } from '../../assets/styles/BaseStyle';

export const ImageButton = styled.div`
  display: flex;
	flex-wrap: wrap;
  width: '100%';
	justify-content: center;
`;

export const Item = styled(RaisedButton)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 26%;
  margin: 15px 30px;
  padding: 10px;
  overflow: hidden;
  border:${({ selected }) => selected ? `2px solid #BD1E6F;` : `0px solid #BD1E6F;`};
  background-color: #fff;
  img {
    max-width: 100%;
      margin-bottom: 10px;
  }
  label {
    display: block;
  }

  @media(min-width:992px)and (max-width:1275px){
    width: 27%;
    margin: 15px 18px;
  }
  @media(min-width:768px)and (max-width:991px){
    width: 26%;
    margin: 15px 16px;
  }
  @media(min-width:576px)and (max-width:767px){
    width: 26%;
    margin: 12px;
    box-shadow:none;
  }

  @media(min-width:320px)and (max-width:575px){
    width: 26%;
    margin: 15px 10px;
    box-shadow:none;
  }

`;
import styled from "styled-components";
import { RaisedButton } from "../../assets/styles/BaseStyle";

export const CenteredText = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

export const GameInstructionsImage = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: -100px;
  @media (max-width: 991px) {
    margin-top: 0px;
  }

  width: ${({ width }) => width}%;

  @media (max-width: 767px) {
    width: 100%;
  }

  img {
    text-align: center;
    max-width: 100%;
  }
  .error-message {
    height: 100px;
    display: block;
    text-align: center;
    margin: 0 auto;

    img {
      height: 100%;
      margin: 0 auto;
    }
  }
  .error-span {
    background-color: bisque;
  }
`;

export const Button = styled(RaisedButton)`
  width: 100px;
  height: 35px;
  background-color: dodgerblue;
  border-radius: 50px;
  font-size: 20px;
  margin-top: 0px;
  text-align: center;
  border: 0;
  color: black;
  @media (max-width: 767px) {
  }
`;
export const ButtonLabel = styled(RaisedButton)`
  width: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  height: 35px;
  background-color: #bfbfbf;
  font-size: 20px;
  text-align: center;
  border: 0;
  color: black;
  @media (max-width: 767px) {
  }
`;

export const LeftImage = styled.div``;

export const RightImage = styled.div``;

export const NotMobile = styled.div`
  text-align: center;
  max-width: 100%;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const Mobile = styled.div`
  text-align: center;
  @media screen and (min-width: 767px) {
    display: none;
  }
  img {
    max-width: 100%;
  }
`;
export const Flip = styled.div`
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 2s;
    transform-style: preserve-3d;
  }

  .flipImage {
    transform: rotateY(180deg);
  }
`;

export const Flip2 = styled.div`
  .flip-container.active .flipper {
    display: block;
    -webkit-animation: flipanim 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: flipanim 0.5s forwards;
    animation-delay: 0s;
    animation-iteration-count: 1;
    will-change: transform;
    animation-timing-function: linear;
  }

  .flip-container.active .main-img {
    opacity: 0;
  }

  .flip-container {
    margin: 60px auto;
    margin-bottom: 0;
    position: relative;
    width: 650px;
    height: 440px;
    /* margin: 1em auto; */
    @media (max-width: 767px) {
      width: auto;
      height: 225px;
    }
  }

  .flipper {
    transition: 0.5s;
    transform-style: preserve-3d;
    position: relative;
    width: 102px;
    height: 102px;
    margin-left: 132px;
    transform-origin: 0 100%;
    will-change: transform;
    display: none;
  }

  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;

    margin: 0 !important;
  }
  .front img {
    @media (max-width: 360px) {
      height: auto;
      width: 200px;
    }
  }
  .front {
    z-index: 111;
    transform: rotateY(0deg);
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 100%;
    @media (max-width: 360px) {
      overflow: visible;
      left: 112px;
      height: 50px !important;
    }
  }

  .back {
    border: 1px solid #000;
    border-radius: 10px;
    background-color: #cecece;
    transform: rotateY(180deg);
    width: 102px;
    height: 102px;
    margin: 0 !important;
    left: unset !important;
    right: 0px !important;
    top: 0px !important;
  }

  @-webkit-keyframes flipanim {
    0% {
      transform: rotateY(0deg) translateX(0px);
    }
    60% {
      transform: rotateY(150deg) translateX(10px);
    }
    80% {
      transform: rotateY(180deg) translateX(30px);
    }

    100% {
      transform: rotateY(180deg) translateX(30px);
    }
  }

  @keyframes flipanim {
    0% {
      transform: rotateY(0deg) translateX(0px);
    }
    60% {
      transform: rotateY(150deg) translateX(10px);
    }
    80% {
      transform: rotateY(180deg) translateX(30px);
    }

    100% {
      transform: rotateY(180deg) translateX(30px);
    }
  }

  .flip-wrp {
    display: flex;
  }

  .main-img {
    width: 282px;
    height: 290px;
    border-radius: 10px;
    ${({ v2 }) => (v2 ? "" : `position: absolute;`)}
    margin: auto;
    left: 50%;
    transition: left 0.1s;
    top: 0;
    ${({ v2 }) =>
      v2
        ? `@media(max-width:767px){
      width: 200px;
      height: auto;
      left:30%;
    }`
        : `
    transform: translate(-50%);
    @media(max-width:767px){
        width: 142px;
        height: auto;
        left: 50%;
    }`}
  }

  .main-img img {
    width: 100%;
    ${({ v2 }) => v2 || `height: 100%`}
  }

  .main-img.show {
    left: 400px;
    transition: left 0.1s;
  }

  .fixed-img {
    border: 1px solid #000;
    border-radius: 10px;
    background-color: #cecece;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    font-family: monospace;
    transform-style: preserve-3d;
    transition: all 0.2s ease-in-out;
    @media (max-width: 767px) {
      width: 50px;
      height: 50px;
    }
    @media (max-width: 767px) and (min-width: 650px) {
      left: 25%;
    }
    @media (max-width: 649px) and (min-width: 425px) {
      left: 13%;
    }
    @media (max-width: 424px) and (min-width: 375px) {
      left: 8%;
    }
  }

  .fixed-img.active {
    transform: rotateY(180deg);
  }
  .fixed-img > div {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .frontf {
    z-index: 2;
    backface-visibility: hidden;
  }

  .backf {
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }

  .backf img {
    width: 100%;
    height: 100%;
  }
`;
